<template>
    <div>
      <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        <!-- Small filter -->
        <div class="container d-lg-none">
          <div class="row">
            <div class="col">
              <b-button-group
                                  aria-label="Basic example"
                                  data-toggle="buttons">
              <button type="button"  @click="setViewType(button)"
                                       v-for="button in views"
                                       :key="button.value"
                                       :class="button.class"
                                       :aria-pressed="button.state">{{button.text}}</button>
            </b-button-group>
            </div>
            <div class="col-12">
                <base-input type="date"
                    v-model="startDate"
                    @click="getData(view.value, startDate, endDate, productID)"/>

              <base-input type="date"
                          v-model="endDate"
                          @click="getData(view.value, startDate, endDate, productID)"/>
            </div>

            <div class="col-12" v-if="view.value=='productAnalysis'">
              <base-dropdown>
                <base-button slot="title" type="secondary" class="dropdown-toggle"   >
                  Pick P.Group
                </base-button>
                <a class="dropdown-item" v-for="productGroup in dashboardData.productAnalysis.productGroups"
                        :key="productGroup.product_group_id">{{productGroup.product_group}}</a>
              </base-dropdown>
            </div>
            <div class="col-12" v-if="view.value=='productAnalysis'">
              <base-dropdown>
                <base-button slot="title"
                    v-model="productID"
                    type="secondary"
                    class="dropdown-toggle"
                    @click="getData(view.value, startDate, endDate, productID)" >
                  Pick Product
                </base-button>
                <a class="dropdown-item" v-for="product in dashboardData.productAnalysis.products"
                        :key="product.product_id">{{product.product}}</a>
              </base-dropdown>
            </div>
          </div>
        </div>
        <!-- Big filter -->
        <div class="row">
          <div class="col d-none d-lg-block">
            <div class="row">
              <div class="col-2">
                  <base-input type="date"  v-model="startDate" @change="getData(view.value, startDate, endDate, productID)"/>
              </div>
              <div class="col-2">
                <base-input type="date"  v-model="endDate" @change="getData(view.value, startDate, endDate, productID)"/>
              </div>

              <div class="col-2" v-if="view.value=='productAnalysis'">
                <base-dropdown>
                  <base-button slot="title" type="secondary" class="dropdown-toggle"   >
                    Pick P.Group
                  </base-button>
                  <a class="dropdown-item" v-for="productGroup in dashboardData.productAnalysis.productGroups"
                          :key="productGroup.product_group_id">{{productGroup.product_group}}</a>
                </base-dropdown>
              </div>

              <div class="col-2" v-if="view.value=='productAnalysis'">
                <base-dropdown>
                  <base-button slot="title" type="secondary" v-model="productID" class="dropdown-toggle" >
                    Pick Product
                  </base-button>
                  <a class="dropdown-item" v-for="product in dashboardData.productAnalysis.products"
                  @click="getData(view.value, startDate, endDate, product.product_id)"
                          :key="product.product_id">{{product.product}}</a>
                </base-dropdown>
              </div>
              <div class="col-3  offset-md-4" v-if="view.value!='productAnalysis'">
                <b-button-group
                                    aria-label="Basic example"
                                    data-toggle="buttons">
                  <button type="button"  @click="setViewType(button)"
                                           v-for="button in views"
                                           :key="button.value"
                                           :class="button.class"
                                           :aria-pressed="button.state">{{button.text}}</button>
                </b-button-group>
              </div>
              <div class="col-3" v-if="view.value=='productAnalysis'">
                <b-button-group
                                    aria-label="Basic example"
                                    data-toggle="buttons">
                  <button type="button"  @click="setViewType(button)"
                                           v-for="button in views"
                                           :key="button.value"
                                           :class="button.class"
                                           :aria-pressed="button.state">{{button.text}}</button>
                </b-button-group>
              </div>
            </div>
          </div>
        </div>
      </base-header>
      <executiveSummary v-if="view.value=='executiveSummary'" :data="dashboardData.executiveSummary"/>
      <productAnalysis v-if="view.value=='productAnalysis'" :product="productID" :data="dashboardData.productAnalysis"/>
      <weekAnalysis v-if="view.value=='weekAnalysis'" :data="dashboardData.weekAnalysis"/>
    </div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';
  import ExecutiveSummary from '@/dashboards/ExecutiveSummary.vue';
  import ProductAnalysis from '@/dashboards/ProductAnalysis.vue';
  import WeekAnalysis from '@/dashboards/WeekAnalysis.vue';
  export default {
    components: {
      executiveSummary: ExecutiveSummary,
      productAnalysis: ProductAnalysis,
      weekAnalysis: WeekAnalysis,
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        productID: null,
        productGroupID: null,
        dashboardData: { executiveSummary:{ costPrice: 0,
                                            noOfCustomers: 0,
                                            productsSold: 0,
                                            profit: 0,
                                            revenue: 0,
                                            productGroups: [],
                                            products: [],
                                            timelineDaily: [],
                                            timelineWeekly: [],
                                            timelineMonthly: [],
                                            heatmap: []},
                       productAnalysis: { products:[],
                                          complimentaryProducts: [],
                                          productGroups: [],
                                          revenue: 0,
                                          costPrice: 0,
                                          productsSold: 0,
                                          timeline: [],
                                          rankInCategory: 0,
                                          mostPopularCombos: [],
                                          weekdaysVertical: []},
                       weekAnalysis: {
                                          estProfit: 0,
                                          estCostPrice: 0,
                                          weekForecast: [],
                                          timelineDaily: [],
                                          averageCustomers: 0,

                       },
                      },
        view: {value:'executiveSummary',
          text:'Executive',
          class:'btn btn-secondary'},
        views: [{value:'executiveSummary',
            text:'Executive',
            class:"btn btn-outline-secondary",
            state: true},
          {value:'weekAnalysis',
          text:'Week',
          class:"btn btn-outline-secondary",
          state: false},
          {value:'productAnalysis',
          text:'Product',
          class:"btn btn-outline-secondary", state: false},],

      };
    },
    methods: {
      setViewType(view){
        this.view = view;
        this.productID='';
        this.getData(view.value);
        for( var i=0; i<this.views.length;i++){
          this.views[i].class = 'btn btn-outline-secondary';
          this.views[i].state = false;
          this.views[i].class = 'btn btn-outline-secondary';
        }
        this.view.class = 'btn btn-secondary';
        if(this.$route.fullPath.includes('analytics/')){
          this.$router.push(this.view.value);
        } else {
          this.$router.push('analytics/'+this.view.value);
        }
      },
    getData(view, startDate, endDate, productID){
      var path = `${config.backend}/views/${view}?`

      if(startDate){
        path += "startDate="+startDate;
      }
      if(endDate){
        path += "&endDate="+endDate;
      }
      if(productID){
        path += "&product_id="+productID;
      }
      axios.get(path, {headers:{params: this.axiosParams,
                                Authorization:`${localStorage.Authorization}`}})
        .then((result) => {

          this.dashboardData[this.view.value] = result.data[this.view.value];
          this.startDate = result.data[this.view.value].startDate;
          this.endDate = result.data[this.view.value].endDate;
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401) {
            localStorage.removeItem('Authorization');
            window.location = '/';
          }
        });
    },
    handleRouting(){
      var viewType = this.$router.history.current.params.viewType;
      for( var i=0; i<this.views.length;i++){
        if(this.views[i].value==viewType && viewType!=this.view.value){
          this.setViewType(this.views[i]);
        }
      }
      // eslint-disable-next-line
      var startDate = this.$route.query.startDate;
      (startDate) ? this.startDate = startDate:'';
      // eslint-disable-next-line
      var endDate = this.$route.query.endDate;
      (endDate) ? this.endDate = endDate:'';
      },
    },
    mounted() {
      this.handleRouting();
      this.getData(this.view.value, this.startDate, this.endDate, this.productID);
    }
  };
</script>
<style></style>
