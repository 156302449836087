<template>
<modal :show="show" v-on:close="$emit('closeMe')" modal-classes="modal-dialog-centered modal-lg">
      <template slot="header">
        <h5 class="modal-title">User | {{user.name}}</h5>
     </template>
    <div class="row scroll">
      <div class="col">

        <base-input v-model="user.email" placeholder="email" type="email"/>
        <base-input v-model="user.name" placeholder="name"/>
        <div class="row">
          <div class="col">
            Active
          </div>
          <div class="col">
            <label class="custom-toggle">
              <input type="checkbox" checked>
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Type
          </div>
          <div class="col">
            <select v-model="user.type">
              <option v-for="ut in Object.keys(userTypes)" :key="ut"
                  :value="ut">
                {{ut}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col">
        <select class="form-control"
            data-toggle="select"
            v-model="user.businesses"
            multiple data-placeholder="Select multiple options">
          <option v-for="b in businesses" :value="b._id" :key="b._id">{{b.name}}</option>
        </select>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="table-responsive">
        <base-table thead-classes="thead-light"
          :data="Object.keys(user.workflow_notifications)">
          <template slot="columns"  class="d-none d-lg-table-cell">
            <th class="d-lg-table-cell">Workflow Notifications</th>
            <th class="d-none d-lg-table-cell">Notify when</th>
            <th class="d-none d-lg-table-cell">Mail</th>
            <th class="d-none d-lg-table-cell">SMS</th>
          </template>
          <template slot-scope="{row}">
            <td scope="row" >
              <div class="row">
                {{row}}
              </div>
              <br class="row d-md-none"/>
              <div class="row d-md-none">
                <div class="col">
                  <select v-model="user.workflow_notifications[row]['when']">
                    <option v-for="sched in notifyWhen"
                        :key="sched"
                        :value="sched" >
                      {{sched}}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input"
                                          v-model="user.workflow_notifications[row]['email']"
                                          :id="'customCheckMail-'+row">
                    <label class="custom-control-label" :for="'customCheckMail-'+row"></label>
                  </div>
                </div>
                <div class="col">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input"
                                    v-model="user.workflow_notifications[row]['sms']"
                                  :id="'customCheckSMS-'+row">
                    <label class="custom-control-label" :for="'customCheckSMS-'+row"></label>
                  </div>
                </div>
              </div>

            </td>
            <td scope="row"  class="d-none d-lg-table-cell">
              <select v-model="user.workflow_notifications[row]['when']">
                <option v-for="sched in notifyWhen"
                    :key="sched"
                    :value="sched" >
                  {{sched}}
                </option>
              </select>
            </td>
            <td scope="row" class="d-none d-lg-table-cell">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input"
                                      v-model="user.workflow_notifications[row]['email']"
                                      :id="'customCheckMail-'+row">
                <label class="custom-control-label" :for="'customCheckMail-'+row"></label>
              </div>
            </td>
            <td scope="row" class="d-none d-lg-table-cell">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input"
                                v-model="user.workflow_notifications[row]['sms']"
                              :id="'customCheckSMS-'+row">
                <label class="custom-control-label" :for="'customCheckSMS-'+row"></label>
              </div>
            </td>
          </template>
        </base-table>
      </div>
    </div>
      <template slot="footer">
        <base-button type="secondary" class="ml-auto" @click="$emit('cancel')">
          Close
        </base-button>
        <base-button type="success"  @click="$emit('saveUser')">
          Save
        </base-button>
      </template>
    <form>

</form>
</modal>
</template>
<script>
  export default {
    components: {
    },
    props:['businesses','user', 'show','userTypes'],
    data() {
      return {
        notifyWhen: ['NEVER','FAILURE','SUCCESS','ALWAYS'],
      };
    },
    methods: {
    },
    mounted() {
    }
  };
</script>
<style></style>
