<template>
<div  :id="'line-chart-'+noGraph"/>
</template>
<script>
import * as d3 from 'd3';
/* eslint-disable */
export default {
  components: {
  },
  data() {
    return {};
  },
  mounted() {
    this.create(this.metric);
  },
  props: ['data','noGraph','metric','height'],
  methods: {
    create(metric) {
      var bisectDate = d3.bisector(function(d) { return d.date; }).left;
      var size = {width: 1000, height: this.height}
      var margin = {top: 10, right: 30, bottom: 40, left: 40},
          width = size.width - margin.left - margin.right,
          height = size.height - margin.top - margin.bottom;
      d3.select('#line-chart-'+this.noGraph).selectAll("*").remove();
      console.log(d3.select('#line-chart-'+this.noGraph));

      // append the svg object to the body of the page
      console.log('#line-chart'+'-'+this.noGraph);
      var svg = d3.select('#line-chart'+'-'+this.noGraph)
        .append('svg')
        .attr('height', this.height)
        .attr('width', "100%")
        .attr('viewBox','0 0 '+size.width.toString()+' '+size.height.toString())
        .attr('preserveAspectRatio', "none")
        .append('g')
          .attr('transform',
                'translate(' + margin.left + ',' + margin.top + ')');
        this.data.forEach(function(d) {
          d.date = new Date(d.date);
        });

      // create a tooltip
      var tooltip = d3.select('#line-chart'+'-'+this.noGraph)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("border", "solid")
          .style("border-width", "1px")
          .style("padding", "5px")
      // Three function that change the tooltip when user hover / move / leave a cell
        var mouseover = function(d) {
          tooltip.style("opacity", 1)
        }

      var mousemove = function(d, data){
                var x0 = x.invert(d3.pointer(d)[0]),
                i = bisectDate(data, x0, 1),
                d0 = data[i - 1],
                d1 = data[i],
                dat = x0 - d0.date > d1.date - x0 ? d1 : d0;
                tooltip
                  .html('<b>Date: </b>'+dat.date.toISOString().substring(0,10)
                          +'<br><b>'+metric+'</b>: ' + dat[metric])
                  .style("left", (d3.pointer(d)[0]+10) + "px")
                  .style("top", (d3.pointer(d)[1]+140) + "px")
            }

      var mouseleave = function(d) {
          tooltip.style("opacity", 0)
        }
      var x = d3.scaleTime()
        .domain(d3.extent(this.data, function(d) { return d.date; }))
        .range([ 0, width ]);
      svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x));
      var y = d3.scaleLinear()
        .domain([0, d3.max(this.data, function(d) { return +((d[metric]) ? d[metric] : 0); })])
        .range([ height, 0 ]);
      svg.append("g")
        .call(d3.axisLeft(y));
      svg.append("path")
        .datum(this.data)
        .attr("fill", "none")
        .attr("stroke", "#41b883")
        .attr("stroke-width", 4)
        .attr("d", d3.line()
          .defined(function(d) {
              return ((d[metric]) ? d[metric] : 0);
          })
          .x(function(d) { return x(d.date) })
          .y(function(d) { return y(((d[metric]) ? d[metric] : 0)) })
          )
          .attr(metric, function(d) { return d[metric] })
          .attr('date', function(d) { return d.date })
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave)
    }
  },
  watch: {
    data: {
        immediate: true,
        handler(newVal) {
            this.create(this.metric);
        }
    }
  },
};
</script>
<style>
</style>
