<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" v-if="show">
    <side-bar
      :background-color="sidebarBackground"
      :userName="userName"
      >
      <template slot="content">
        <sidebar-item v-for="page in allowedPages.content"
            :key="page.url"
            :link="{name: page.name, icon: page.icon, path: page.url}"/>
      </template>
      <template slot="management" v-if="type=='ADMIN_GLOBAL'">
        <sidebar-item v-for="page in allowedPages.management"
            :key="page.url"
            :link="{name: page.name, icon: page.icon, path: page.url}"/>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <b-row>
      <dashboard-navbar
        v-if="show"
        :userName="userName"></dashboard-navbar>
      </b-row>
      <div @click="toggleSidebar">
        <div class="container-xl bg-red text-white" style="position:absolute;z-index:5"
            v-if="environment!='PRODUCTION' || type=='ADMIN_GLOBAL'">
            <div class="row">
              <div class="col-8">
              RUNNING IN {{environment}} AS USER {{userName.toUpperCase()}}
              </div>
              <div class="col"  v-if="type=='ADMIN_GLOBAL'">
                <select v-model="chosenUser" @change="setLogin(chosenUser)">
                  <option v-for="usr in users" :key="usr._id"
                      :value="usr">
                    {{usr.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>

        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- Content here -->

          <router-view :key="$route.path"/>
        </fade-transition>
        <content-footer
            v-if="!$route.meta.hideFooter && show"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';
  import DashboardNavbar from '@/main/DashboardNavbar.vue';
  import ContentFooter from '@/main/ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    props: ["show"],
    data() {
      return {
        allowedPages: [],
        environment: null,
        access_rights: null,
        userName: 'S V',
        users: [],
        type: null,
        chosenUser: {},
        sidebarBackground: 'default', //vue|blue|orange|green|red|primary
        adminToken: null,
      };
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      setLogin(){
      const path = `${config.backend}${config.login}`;
      axios.put(path, this.chosenUser, {headers:{ Authorization:`${this.adminToken}`}})
        .then((result) => {
          localStorage.setItem('Authorization',result.data.Authorization);
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401) {
            this.showLoginMessage = true;
          }
        });
      },
      getUserContent() {
        const path = `${config.backend}/status`;
        axios.get(path, {headers:{ Authorization:`${localStorage.Authorization}`}})
        .then((result) => {
          localStorage.setItem('userName', result.data.name);
          this.userName = localStorage.userName;
          this.email = result.data.email;
          this.environment = result.data.environment;
          this.type = result.data.type;
          this.allowedPages = result.data.pages;
          if(this.type=='ADMIN_GLOBAL'){
            this.adminToken = localStorage.Authorization;
            this.getUsers();
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401) {
            this.authenticated = false;
            localStorage.removeItem('Authorization');
          }
        });
      },
      getUsers(){
        var path = `${config.backend}${config.users}`
        axios.get(path, {headers:{ Authorization:`${this.adminToken}`}})
          .then((result) => {
            this.users = result.data.users;
          })
          .catch((error) => {
            console.log(error);

          });
      },
    },
    mounted() {
      this.getUserContent();
    },
  };
</script>
