<template>
  <div>
    <base-header type="gradient-default" class="pb-6 pb-8 pt-5 pt-md-7">
      <div class="container">
        <div class="row" v-if="showAlert">
          <div class="col-xl-12">
            <div :class="'alert alert-'+msgType" role="alert">
                {{msg}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="table-responsive">
                <base-table thead-classes="thead-light"
                  :data="credentials">
                <template slot="columns"  class="d-none d-lg-table-cell">

                  <th class="d-lg-table-cell">Business</th>
                  <th class="d-lg-table-cell">Platform</th>
                  <th class="d-none d-lg-table-cell">Scopes</th>
                  <th class="d-lg-table-cell"/>
                </template>
                <template slot-scope="{row}">
                  <td scope="row"  class="d-lg-table-cell">
                  {{row.business}}
                  </td>
                  <td scope="row" class="d-lg-table-cell">
                  {{row.platform}}
                  </td>
                  <td scope="row" class="d-none d-lg-table-cell">
                  <h5 v-for="s in row.scopes" :key="s">{{s}}</h5>
                  </td>
                  <td scope="row" >
                    <div class="row">
                      <div class="col">
                      <a :href="row.auth_url">
                        <i class="ni ni-key-25 text-green"/>
                      </a>
                      </div>
                      <div class="col">

                        <i class="ni ni-check-bold text-blue"
                                  @click="pingCredentials(row.business,row.platform)"/>
                      </div>
                   </div>
                  </td>
                </template>
                </base-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';

  export default {
    components: {
    },
    data() {
      return {
        showAlert: false,
        msgType:'danger',
        msg: '',
        credentials: []
      };
    },
    methods: {
      getCredentials(){
        var path = `${config.backend}${config.credentials}`
        axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.credentials = result.data.credentials;
          })
          .catch((error) => {
            console.log(error);
            // eslint-disable-next-line
          });
      },
      saveAuthorizationCode(){
        var code = this.$router.history.current.query.code
        var platform = this.$router.history.current.params.platform;
        console.log(this.$router.history.current.query)
        var business_id = this.$router.history.current.query.state;
        var path = `${config.backend}${config.credentials}/${business_id}/${platform}?code=${code}`
        axios.put(path,{}, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.credentials = result.data.credentials;
          })
          .catch((error) => {
            console.log(error);
            this.msg = error.response.data;
            this.msgType = 'danger';

          });
      },
      pingCredentials(business, platform){
        var path = `${config.backend}${config.credentials}/${business}/${platform}`
        axios.post(path,{}, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.showAlert = true;
            this.msg = result.data;
            this.msgType = 'success';
          })
          .catch((error) => {
            this.showAlert = true;
            this.msg = error.response.data;
            this.msgType = 'danger';
            // eslint-disable-next-line

          });
        setTimeout(() => {  this.showAlert=false; }, 3000);
      },
    },
    mounted() {
      this.getCredentials()
      if(this.$router.history.current.params.platform){
        this.saveAuthorizationCode();
      }
    }
  };
</script>
<style></style>
