<template>
  <div :id="'heatmap-'+id"/>
</template>
<script>
import * as d3 from 'd3';
/* eslint-disable */
export default {
  data() {
    return {
     cSize: {height:350, width:400},
    };
  },
  props: ['data','xAxisDimension','yAxisDimension','metric','id'],
  methods: {
    create(data, chartSize, xAxisDimension, yAxisDimension, metric,id) {
      d3.select('#heatmap-'+id).selectAll('*').remove();
      // set the dimensions and margins of the graph
      var margin = {top: 20, right: 20, bottom: 30, left: 65},
        width = chartSize.width - margin.left - margin.right,
        height = chartSize.height - margin.top - margin.bottom;
      d3.select('#heatmap-'+id).selectAll("*").remove();
      // append the svg object to the body of the page
      var svg = d3.select('#heatmap-'+id)
      .append("svg")
        .attr('height', chartSize.height.toString())
        .attr('width', "100%")
        .attr('viewBox','0 0 '+chartSize.width.toString()+' '+chartSize.height.toString())
        .attr('preserveAspectRatio', "none")
      .append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");

      // Labels of row and columns
      var myGroups = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Sunday"]
      var myVars = ["9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21"]

      // Build X scales and axis:
      var x = d3.scaleBand()
        .range([ 0, width ])
        .domain(myVars)
        .padding(0.01);
      svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x))

      // Build X scales and axis:
      var y = d3.scaleBand()
        .range([ height, 0 ])
        .domain(myGroups)
        .padding(0.01);
      svg.append("g")
        .call(d3.axisLeft(y));

      // Build color scale
      var myColor = d3.scaleLinear()
        .range(["white", "#41b883"])
        .domain([1,100])

      // create a tooltip
      var tooltip = d3.select("#heatmap-"+id)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("border", "solid")
          .style("border-width", "1px")
          .style("padding", "5px")

        // Three function that change the tooltip when user hover / move / leave a cell
        var mouseover = function(d) {
          tooltip.style("opacity", 1)
        }
        var mousemove = function(d){
                              tooltip
                                .html('<b>'+d.target.attributes[yAxisDimension].value +
                                        ' '+
                                        d.target.attributes[xAxisDimension].value
                                        +"</b>: " + d.target.attributes[metric].value)
                                .style("left", (d.layerX+30) + "px")
                                .style("top", (d.layerY+130) + "px")
                            }
        var mouseleave = function(d) {
          tooltip.style("opacity", 0)
        }
      //Read the data
        svg.selectAll()
          .data(data, function(d) {return d[xAxisDimension]+':'+d[yAxisDimension];})
          .enter()
          .append("rect")
          .attr("x", function(d) { return x(d[xAxisDimension]) })
          .attr("y", function(d) { return y(d[yAxisDimension]) })
          .attr(metric, function(d) { return d[metric] })
          .attr(xAxisDimension, function(d) { return d[xAxisDimension] })
          .attr(yAxisDimension, function(d) { return d[yAxisDimension] })
          .attr("width", x.bandwidth() )
          .attr("height", y.bandwidth() )
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave)
          .transition()
          .duration(1500)
          .style("fill", function(d) { return myColor(d[metric])} )
          .delay(function (d, i) { return (i); });
    },
  },
  computed: {
  },
  watch: {
    data: {
       immediate: true,
       handler(newVal) {
           this.create(this.data, this.cSize, this.xAxisDimension, this.yAxisDimension, this.metric, this.id);
       }
    }
  },
};
</script>
