<template>
<modal :show="show" v-on:close="$emit('closeMe')" modal-classes="modal-dialog-centered modal-sm">
  <div class="col">
    <div class="row">
      <h3>{{message}}</h3>
    </div>
    <br/>
    <div class="row">
      <div class="col">
       <base-button outline :type="cancellationButtonType"  class="ml-auto" @click="$emit('cancelled')">
          {{cancellationButton}}
        </base-button>
      </div>
      <div class="col">
       <base-button :type="confirmationButtonType" @click="$emit('confirmed')">{{confirmationButton}}</base-button>
      </div>

    </div>
  </div>


</modal>
</template>
<script>

  export default {
    components: {
    },
    props:['show',
    'message',
    'confirmationButtonType',
    'confirmationButton',
    'cancellationButton',
    'cancellationButtonType'],
    data() {
      return {
        users: []
      };
    },
    methods: {

    },
    mounted() {
    }
  };
</script>
<style></style>
