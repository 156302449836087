<template>
  <div class="col">
      <div class="row justify-content-center">
          <div class="col-lg-5 col-md-7">
          <br>
          <br>
          <br>
          <br>
              <div class="card bg-secondary shadow border-0">

                  <div class="card-body px-lg-5 py-lg-5">
                      <form role="form" v-if="!showForgotPasswordFormula">
                          <base-alert v-if="showLoginMessage" type="danger" :message="message">
                            <template>
                              <slot type="type">{{message.text}}</slot>
                            </template>
                          </base-alert>
                          <base-input class="input-group-alternative mb-3"
                                      placeholder="Email"
                                      type="email"
                                      addon-left-icon="ni ni-email-83"
                                      v-on:keyup.enter.native="login()"
                                      v-model="user.email">
                          </base-input>

                          <base-input class="input-group-alternative"
                                      placeholder="Password"
                                      type="password"
                                      addon-left-icon="ni ni-lock-circle-open"
                                      v-on:keyup.enter.native="login()"
                                      v-model="user.password">
                          </base-input>
                          <div class="row mt-3">
                              <div class="col-6 text-left">
                                  <a  class="text-light" @click="showForgotPassword()"><small>Forgot password?</small></a>
                              </div>
                          </div>
                          <div class="text-center">
                              <base-button type="primary" class="my-4" @click.native="login()">Sign in</base-button>
                          </div>
                      </form>
                      <form role="form" v-if="showForgotPasswordFormula">
                          <base-alert v-if="showLoginMessage" type="danger" :message="message">
                            <template>
                              <slot type="type">{{message.text}}</slot>
                            </template>
                          </base-alert>
                          <base-input class="input-group-alternative mb-3"
                                      placeholder="Email"
                                      addon-left-icon="ni ni-email-83"
                                      v-on:keyup.enter.native="login()"
                                      v-model="user.email">
                          </base-input>
                          <div class="text-center">
                              <base-button type="primary"
                                            class="my-4"
                                            @click.native="forgotPassword(user.email)">
                                            <i class="ni ni-email-83"></i>
                                            Send email
                              </base-button>
                          </div>
                      </form>
                  </div>
              </div>

          </div>
      </div>
  </div>
</template>
<script>
import axios from 'axios';
import config from '@/config';

export default {
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      showForgotPasswordFormula: false,
      showLoginMessage: false,
      message: {
        variant: 'danger',
        text: 'Invalid email and/or password',
      },
    };
  },
  components: {
  },
  methods: {
    login() {
      const path = `${config.backend}${config.login}`;
      axios.post(path, this.user)
        .then((result) => {
          localStorage.setItem('Authorization', result.data.Authorization);
          window.location = '/';
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401) {
            this.showLoginMessage = true;
          }
        });
    },
    showForgotPassword(){
      this.showLoginMessage = false;
      this.showForgotPasswordFormula=true;
    },
    forgotPassword(email){
      const path = `${config.backend}${config.forgotPassword}/${email}`;
        axios.post(path)
          .then((result) => {
            console.log(result.data);
          })
          .catch((error) => {
            // eslint-disable-next-line
            if (error.response.status === 401) {
              this.showLoginMessage = true;
            }
          });
    },
  },
  created() {
  },
};
</script>
<style>
.modal-dialog {
}
.modal-backdrop
{
    opacity:1 !important;
    width: 100%;
    height: 100%;
}
.close
{
  display: none;
}
</style>
