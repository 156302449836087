<template>
<div>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
      <confirmationPopUp :show="showDeleteUserConfirmation"
          :message="`Are you sure you want to remove ${user.name}?`"
          :confirmedButtonType="'danger'"
          :confirmationButton="'Yes, remove.'"
          :cancellationButton="'No.'"
          @confirmed="deleteUser(user._id)"
          @cancelled="showDeleteUserConfirmation=false"/>
      <confirmationPopUp :show="showInviteConfirmation"
          :message="`Send invite to ${user.name}?`"
          :confirmedButtonType="'success'"
          :confirmationButton="'Invite.'"
          :cancellationButton="'No.'"
          @confirmed="sendUserInvite(user.email)"
          @cancelled="showInviteConfirmation=false"/>
      <confirmationPopUp :show="showSendForgotPWConfirmation"
          :message="`Send forgot password email to ${user.name}?`"
          :confirmedButtonType="'success'"
          :confirmationButton="'Send.'"
          :cancellationButton="'No.'"
          @confirmed="sendForgotPasswordMail(user.email)"
          @cancelled="showSendForgotPWConfirmation=false"/>
      <modalUser :businesses="businesses" :user="user" :userTypes="userTypes"
      :show="showUserModal"
          @cancel="showUserModal=false"
          @saveUser="saveUser"/>
          <div class="col-xl-12">
              <div class="card">
                  <div class="card-header border-0">
                      <div class="row align-items-center">
                          <div class="col">
                            <h5 class="h3 mb-0">Users</h5>
                          </div>
                              <div class="col">
                                  <ul class="nav nav-pills justify-content-end">
                                      <li class="nav-item">
                                          <a class="nav-link py-2 px-3"
                                             :class="{active: 1}"
                                              >
                                              <span class="d-none d-md-block" @click="addUser()">Add User</span>
                                              <span class="d-md-none"  @click="addUser()">Add</span>
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                      </div>
                  </div>
                  <div class="table-responsive">
                      <base-table thead-classes="thead-light"
                                  :data="users">
                          <template slot="columns">
                            <th class="d-none d-lg-block">ID[:5]</th>
                            <th>Name</th>
                            <th class="d-none d-md-table-cell">Email</th>
                            <th class="d-none d-md-table-cell">Last Login</th>
                            <th class="d-none d-md-table-cell"></th>
                          </template>
                          <template slot-scope="{row}">
                              <th scope="row" class="d-none d-lg-block">
                                {{row._id.substr(row._id.length-5,row._id.length)}}
                              </th>
                              <td>
                              <div class="row">
                                {{row.name}}
                              </div>
                              <br class="row d-md-none"/>
                              <div class="row d-md-none"  @click="setUser(row)">
                                <div class="col">
                                 <i class="ni ni-settings-gear-65"
                                     title="Configure"
                                     @click="showUserModal=true"></i>
                                </div>

                                <div class="col">
                                 <i class="ni ni-email-83"
                                    title="Invite"
                                    @click="showInviteConfirmation=true"
                                 ></i>
                                </div>
                                <div class="col">
                                 <i class="ni ni-key-25"
                                   title="Send Password email"
                                   @click="showSendForgotPWConfirmation=true"></i>
                                </div>
                                <div class="col">
                                 <i class="ni ni-fat-remove"
                                    title="Delete user"
                                  @click="showDeleteUserConfirmation=true"></i>
                                </div>
                              </div>
                              </td>
                              <td class="d-none d-md-table-cell">
                                {{row.email}}
                              </td>
                              <td class="d-none d-lg-block">
                                {{row.last_login}}
                              </td>
                              <td class="d-none d-md-table-cell">
                              <div class="row" @click="setUser(row)">
                                <div class="col">
                                 <i class="ni ni-settings-gear-65"
                                     title="Configure"
                                     @click="showUserModal=true"></i>
                                </div>

                                <div class="col">
                                 <i class="ni ni-email-83"
                                    title="Invite"
                                    @click="showInviteConfirmation=true"
                                 ></i>
                                </div>
                                <div class="col">
                                 <i class="ni ni-key-25"
                                   title="Send Password email"
                                   @click="showSendForgotPWConfirmation=true"></i>
                                </div>
                                <div class="col">
                                 <i class="ni ni-fat-remove"
                                    title="Delete user"
                                  @click="showDeleteUserConfirmation=true"></i>
                                </div>
                              </div>
                              </td>
                          </template>
                    </base-table>
                  </div>
              </div>
          </div>
   </base-header>
</div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';
  import ModalUser from '@/components/ModalUser.vue';
  import User from '@/data-models/user.json';
  import UserTypes from '@/data-models/userTypes.json';
  import ConfirmationPopUp from '@/components/ConfirmationPopUp.vue';
  export default {
    components: {
     modalUser: ModalUser,
     confirmationPopUp: ConfirmationPopUp,
    },
    data() {
      return {
        showUserModal: false,
        showDeleteUserConfirmation: false,
        showInviteConfirmation: false,
        showSendForgotPWConfirmation: false,
        users: [],
        businesses: [],
        user: User,
        userTypes:UserTypes,
      };
    },
    methods: {
      setUser(user){
        this.user = user;
      },

      getBusinesses(){
        var path = `${config.backend}${config.businesses}?`
        axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.businesses = result.data.businesses;
          })
          .catch((error) => {
            console.log(error);
            // eslint-disable-next-line

          });
      },
      getUsers(){
        var path = `${config.backend}${config.users}`
        axios.get(path, {headers:{ Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.users = result.data.users;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      addUser(){
        this.user = User;
        this.showUserModal = true;
      },
      saveUser(){
        if(this.user._id){
          this.changeUser(this.user);
        } else {
          this.createUser(this.user);
        }
        this.showUserModal = false;
      },
      createUser(user){
        delete user["_id"]
        var path = `${config.backend}${config.users}`
        // eslint-disable-next-line
        axios.post(path, user, {headers:{ Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.getUsers();
            this.message = result.data.message;
          })
      },
      changeUser(user){
        var path = `${config.backend}${config.users}/${user._id}`
        // eslint-disable-next-line
        axios.put(path, user, {headers:{ Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.getUsers();
            this.message = result.data.message;
          })
      },
      deleteUser(userID){
        var path = `${config.backend}${config.users}/${userID}`
        // eslint-disable-next-line
        axios.delete(path, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.getUsers();
            this.message = result.data.message;
          })
          .catch((error) => {
            console.log(error);
          });
        this.showDeleteUserConfirmation= false;
      },
      sendForgotPasswordMail(email){
        const path = `${config.backend}${config.forgotPassword}/${email}`;
          axios.post(path, {}, {headers:{ Authorization:`${localStorage.Authorization}`}})
            .then((result) => {
              console.log(result.data);
              this.showSendForgotPWConfirmation= false;
            })
            .catch((error) => {
              // eslint-disable-next-line
              if (error.response.status === 401) {
                this.showLoginMessage = true;
              }
            });
      },
      sendUserInvite(email){
        const path = `${config.backend}${config.sendInvite}/${email}`;
          axios.post(path, {}, {headers:{ Authorization:`${localStorage.Authorization}`}})
            .then((result) => {
              console.log(result.data);
              this.showInviteConfirmation= false;
            })
            .catch((error) => {
              // eslint-disable-next-line
              if (error.response.status === 401) {
                this.showLoginMessage = true;
              }
            });
      },
    },
    mounted() {
      this.getUsers()
      this.getBusinesses()
    }
  };
</script>
<style></style>
