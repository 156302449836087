<template>
  <div>
    <base-header type="gradient-default" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="container">
        <div class="row">
          <div class="col d-none d-md-block">
            <div :key="commit._id" v-for="(commit, index) in changes">
              <div :class="(index%2==1)?'row justify-content-end':'row justify-content-start'">
                <div class="col-5">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title block">{{commit.timestamp}} | <a :href="commit.url">{{commit.sha.substring(0,10)}}</a></h4>
                      <p class="card-text"> {{commit.message}}</p>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
          <div class="col d-md-none">
            <div :key="commit._id" v-for="commit in changes">
              <div class="row">
              <div class="col-1">
                <div class="line"/>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title block">{{commit.timestamp}} | {{commit.endpoint}}</h4>
                    <p class="card-text"> {{commit.header}}</p>
                  </div>
                </div>
                <br>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>

  </div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';
  export default {
    components: {
    },
    data() {
      return {
        changes: [],
         view: {value:'users',
            text:'Users',
            class:'btn btn-secondary'},
        views: [{value:'users',
              text:'Users',
              class:"btn btn-outline-secondary",
              state: true},
            {value:'development',
            text:'Development',
            class:"btn btn-outline-secondary",
            state: false},
            {value:'runs',
            text:'Runs',
            class:"btn btn-outline-secondary", state: false},
            ],
      };
    },
    methods: {
      setUser(user){
        this.user = user;
      },
      getCommits(){
        var path = `${config.backend}${config.commits}`
        axios.get(path, {headers:{ Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.changes = result.data.commits;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getCommits()
    }
  };
</script>
<style>
.line {
  border-left: 6px solid green;
  height: 100%;
  left: 50%;
  margin-left: -3px;
  top: 0;
}
</style>
