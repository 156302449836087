<template>
    <base-nav class="navbar-top navbar-dark"
              id="navbar-main"
              :show-toggle-button="false"
              expand>

        <ul class="navbar-nav align-items-center d-none d-md-flex ml-md-auto">
            <li class="nav-item dropdown">
                <base-dropdown class="nav-link pr-0" position="right">
                    <div class="media align-items-center" slot="title">

                    <span class="avatar avatar-sm rounded-circle">
                      {{userName.includes(' ') ? userName.split(' ')[0][0] + userName.split(' ')[1][0] : userName.substring(0,2)}}
                    </span>
                        <div class="media-body ml-2 d-md d-lg-block">
                            <span class="mb-0 text-sm  font-weight-bold">{{userName}}</span>
                        </div>
                    </div>
                    <template>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-single-02"></i>
                            <span>Profile</span>
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <router-link to="/login" class="dropdown-item" @click="logOut">
                            <i class="ni ni-user-run" @click="logOut"></i>
                            <span @click="logOut">Log out</span>
                        </router-link>
                    </template>
                </base-dropdown>
            </li>
        </ul>
    </base-nav>
</template>
<script>
  export default {
    data() {
      return {
        activeNotifications: false,
        searchQuery: ''
      };
    },
    props: ['userName'],
    methods: {
      logOut() {
        localStorage.removeItem('Authorization');
        window.location = '/login';
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      }
    }
  };
</script>
