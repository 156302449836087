<template>
  <div>
    <base-header type="gradient-default" class="pb-6 pb-8 pt-5 pt-md-7">
      <div class="row">
        <div class="col">
              <div class="row">
                <div class="col">
                <h3 class="text-white">Password</h3>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <h3 class="text-white">Notifications</h3>
                  <div class="card">
                    <div class="table-responsive">
                      <base-table thead-classes="thead-light"
                              :data="Object.keys(workflowNotifications)">
                              <template slot="columns"  class="d-none d-lg-table-cell">
                                <th class="d-lg-table-cell">Workflow</th>
                                <th class="d-none d-lg-table-cell">Notify when</th>
                                <th class="d-none d-lg-table-cell">Mail</th>
                                <th class="d-none d-lg-table-cell">SMS</th>
                              </template>
                              <template slot-scope="{row}">
                                <td scope="row" >
                                   <div class="col">
                                     <div class="row">
                                      {{row}}
                                     </div>
                                     <br class="d-lg-none"/>
                                     <div class="row d-lg-none">
                                       <div class="col">
                                        WHEN:
                                        <select v-model="workflowNotifications[row]['when']">
                                          <option v-for="sched in notifyWhen"
                                              :key="sched"
                                              :value="sched" >
                                            {{sched}}
                                          </option>
                                        </select>
                                       </div>
                                       <div class="col">
                                        <div class="row">
                                          <div class="col">
                                          SMS:
                                          </div>
                                          <div class="col">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input"
                                                                    v-model="workflowNotifications[row]['sms']"
                                                                    :id="'customCheckSMS-'+row">
                                              <label class="custom-control-label" :for="'customCheckSMS-'+row"></label>
                                            </div>
                                        </div>
                                        </div>
                                       </div>
                                       <div class="col">
                                         <div class="row">
                                          <div class="col">
                                            MAIL:
                                          </div>
                                          <div class="col">
                                            <div class="custom-control custom-checkbox">
                                              <input type="checkbox" class="custom-control-input"
                                                                    v-model="workflowNotifications[row]['email']"
                                                                    :id="'customCheckMail-'+row">
                                              <label class="custom-control-label" :for="'customCheckMail-'+row"></label>
                                            </div>
                                          </div>

                                         </div>
                                       </div>
                                     </div>
                                   </div>

                                </td>
                                <td scope="row"  class="d-none d-lg-table-cell">
                                  <select v-model="workflowNotifications[row]['when']">
                                    <option v-for="sched in notifyWhen"
                                        :key="sched"
                                        :value="sched" >
                                      {{sched}}
                                    </option>
                                  </select>
                                </td>
                                <td scope="row" class="d-none d-lg-table-cell">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input"
                                                          v-model="workflowNotifications[row]['email']"
                                                          :id="'customCheckMail-'+row">
                                    <label class="custom-control-label" :for="'customCheckMail-'+row"></label>
                                  </div>
                                </td>
                                <td scope="row" class="d-none d-lg-table-cell">
                                  <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input"
                                                    v-model="workflowNotifications[row]['sms']"
                                                  :id="'customCheckSMS-'+row">
                                    <label class="custom-control-label" :for="'customCheckSMS-'+row"></label>
                                  </div>
                                </td>
                              </template>
                            </base-table>
                     </div>
                  </div>
                  </div>
                </div>
              <h3 class="text-white"></h3>

        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
  import User from '@/data-models/user.json';
  import config from '@/config';
  import axios from 'axios';


  export default {
    components: {
    },
    data() {
      return {
      workflowNotifications: {},
      user: User,
      notifyWhen: ['NEVER','FAILURE','SUCCESS','ALWAYS'],
      };
    },
    methods: {
      getUser(){
        const path = `${config.backend}/users`;
        axios.get(path,{headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.user = result.data
            this.tokenValidated=true;
            this.getWorkflowNotifications(this.user);
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getWorkflowNotifications(user){
        var path = `${config.backend}${config.workflow_notifications}/${user._id}/workflows`
        axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.workflowNotifications = result.data;
          })
          .catch((error) => {
            console.log(error);
            // eslint-disable-next-line

          });
      },
    },
    mounted() {
      this.getUser()
    }
  };
</script>
<style></style>
