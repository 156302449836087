<template>
  <div>
  <base-header type="gradient-default" class="pb-6 pb-8 pt-5 pt-md-9">
      <confirmationPopUp :show="showStopOperations"
          :message="`Are you sure you want to stop all operations?`"
          :confirmationButtonType="'danger'"
          :confirmationButton="'Yes'"
          :cancellationButton="'No'"
          @confirmed="stopOperations()"
          @cancelled="showStopOperations=false"/>
  <div class="container">
  <div class="row">
      <div class="col">
        <h1 class="text-white">Welcome, {{userName}}!</h1>
      </div>
      <div class="col" v-if="false">
        <base-button class="btn btn-outline-danger justify-content-end"
          @click="showStopOperations=true;">
          <i class="ni ni-button-pause text-red"/>
              Stop Operations
        </base-button>
        <a href="mailto:support@chron.cloud?subject=Dear support!&body=">
        <base-button class="btn btn-outline-primary justify-content-end">
              <i class="ni ni-chat-round text-blue"/>
            Contact Support
        </base-button>
            </a>
      </div>
  </div>
  <br/>
  <div class="row">
    <div class="col-lg-4">
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Active</h5>
            <span class="h2 font-weight-bold mb-0">{{view.activeWorkflows}}</span>
            </div>
            <div class="col-auto">
            <div class="icon icon-shape bg-green text-white rounded-circle shadow">
            <i class="ni ni-button-play"></i>
            </div>
            </div>
          </div>
        <p class="mt-3 mb-0 text-sm">
        <span class="text-success mr-2">{{view.runningStatus}}</span>
        <span class="text-nowrap">is running successfully</span>
        </p>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <br class="d-lg-none"/>
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Next runs</h5>
            <span class="h2 font-weight-bold mb-0">{{view.nextRun}}</span>
            </div>
            <div class="col-auto">
            <div class="icon icon-shape bg-gray text-white rounded-circle shadow">
            <i class="ni ni-time-alarm"></i>
            </div>
            </div>
          </div>
        <p class="mt-3 mb-0 text-sm">
        <span class="text-nowrap">Last run was </span>
        <span class="text-success mr-2">{{view.lastRun}}</span>

        </p>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <br class="d-lg-none"/>
      <div class="card card-stats">
        <!-- Card body -->
        <div class="card-body">
          <div class="row">
            <div class="col">
            <h5 class="card-title text-uppercase text-muted mb-0">Total workflows</h5>
            <span class="h2 font-weight-bold mb-0">{{view.totalWorkflows}}</span>
            </div>
            <div class="col-auto">
            <div class="icon icon-shape bg-orange text-white rounded-circle shadow">
            <i class="ni ni-collection"></i>
            </div>
            </div>
          </div>
        <p class="mt-3 mb-0 text-sm">
        <span class="text-nowrap">Last updated at </span>
        <span class="text-success mr-2">{{view.workflowsLastUpdated}}</span>

        </p>
        </div>
      </div>
    </div>

  </div>
  </div>
  </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from 'axios';
import config from '@/config';
import ConfirmationPopUp from '@/components/ConfirmationPopUp.vue'

export default {
  components: {
    confirmationPopUp: ConfirmationPopUp,
  },
  props:[],
  data() {
    return {
      showStopOperations: false,
      view: {totalWorkflows: null,
              runningStatus: null,
              activeWorkflows: null,
              lastRun: null,
              workflowsLastUpdated: null,
              nextRun: null},
      userName: '',
    };
  },
  methods: {
    setUserName(){
      this.userName = localStorage.userName;
    },
    stopOperations(){
      console.log('salmon');
    },
    getLoginView(){
      var path = `${config.backend}/views/loginView`
      axios.get(path, {headers:{params: this.axiosParams,
                                Authorization:`${localStorage.Authorization}`}})
        .then((result) => {
          this.view= result.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401) {
            localStorage.removeItem('Authorization');
            window.location = '/';
          }
        });
    },
  },
  mounted() {
    this.setUserName();
    this.getLoginView();

  },
  created() {
  },
  watch: {
    localStorage: {
        immediate: true,
        handler(newVal) {
          this.setUserName();
        }
    }
  }
};
</script>
<style>
</style>
