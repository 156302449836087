<template>
  <div>
    <base-header type="gradient-default" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="container">
        <div class="row">
          <div class="col">
            <b-button-group
                                aria-label="Basic example"
                                data-toggle="buttons">
              <button type="button"  @click="setViewType(button)"
                                       v-for="button in views"
                                       :key="button.value"
                                       :class="button.class"
                                       :aria-pressed="button.state">{{button.text}}</button>
            </b-button-group>
          </div>
          <div class="col">
            {{onlyShowMe}}
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input"
                                    id="onlyShowMeCheck"
                                    v-model="onlyShowMe"
                                    @click="getActivities();">
              <label class="custom-control-label" for="onlyShowMeCheck">
              <h4 style="color:white;">Only show my activity</h4>
              </label>
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col d-none d-md-block">
            <div :key="act._id" v-for="(act, index) in activities">
              <div :class="(index%2==1)?'row justify-content-end':'row justify-content-start'">
                <div class="col-5">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title block">{{act.timestamp}} | {{act.endpoint}}</h4>
                      <p class="card-text"> {{act.header}}</p>
                    </div>
                  </div>
                  <br>
                </div>
              </div>
            </div>
          </div>
          <div class="col d-md-none">
            <div :key="act._id" v-for="act in activities">
              <div class="row">
              <div class="col-1">
                <div class="line"/>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title block">{{act.timestamp}} | {{act.endpoint}}</h4>
                    <p class="card-text"> {{act.header}}</p>
                  </div>
                </div>
                <br>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>

  </div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';
  export default {
    components: {
    },
    data() {
      return {
        activities: [],
         view: {value:'users',
            text:'Users',
            class:'btn btn-secondary'},
        views: [{value:'users',
              text:'Users',
              class:"btn btn-outline-secondary",
              state: true},
            {value:'development',
            text:'Development',
            class:"btn btn-outline-secondary",
            state: false},
            {value:'runs',
            text:'Runs',
            class:"btn btn-outline-secondary", state: false},
            ],
        onlyShowMe: false,
      };
    },
    methods: {
      setUser(user){
        this.user = user;
      },
      setViewType(view){
        this.view = view;
        this.getData(view.value);
        for( var i=0; i<this.views.length;i++){
          this.views[i].class = 'btn btn-outline-secondary';
          this.views[i].state = false;
          this.views[i].class = 'btn btn-outline-secondary';
        }
        this.view.class = 'btn btn-secondary';
        if(this.$route.fullPath.includes('watchtower/')){
          this.$router.push(this.view.value);
        } else {
          this.$router.push('watchtower/'+this.view.value);
        }
      },
      getActivities(){
        var path = `${config.backend}${config.activity}${(this.onlyShowMe)? '?currentUserOnly=true' : ''}`;
        axios.get(path, {headers:{ Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.activities = result.data.activities;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getActivities()
    }
  };
</script>
<style>
.line {
  border-left: 6px solid green;
  height: 100%;
  left: 50%;
  margin-left: -3px;
  top: 0;
}
</style>
