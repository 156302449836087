<template>
<div  :id="'confidence-line-chart'+'-'+noGraph"/>
</template>
<script>
import * as d3 from 'd3';
/* eslint-disable */
export default {
  components: {
  },
  data() {
    return {};
  },
  props: ['data','noGraph','metric','lowerBound', 'upperBound'],
  methods: {
    create(metric, lowerBound, upperBound) {
      var margin = {top: 10, right: 10, bottom: 30, left: 30},
          width = 400 - margin.left - margin.right,
          height = 200 - margin.top - margin.bottom;
      // append the svg object to the body of the page
      d3.select('#confidence-line-chart'+'-'+this.noGraph).selectAll("*").remove();
      var svg = d3.select('#confidence-line-chart'+'-'+this.noGraph)
        .append('svg')
        .attr('height', "100%")
        .attr('viewBox','0 0 400 200')
        .append('g')
          .attr('transform',
                'translate(' + margin.left + ',' + margin.top + ')');
        this.data.forEach(function(d) {
          d.date = new Date(d.date);
        });
        var x = d3.scaleTime()
          .domain(d3.extent(this.data, function(d) { return d.date; }))
          .range([ 0, width ]);
        svg.append("g")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x));
        var y = d3.scaleLinear()
          .domain([d3.min(this.data, function(d) { return +((d[lowerBound]) ? d[lowerBound] : 0); }),
                  d3.max(this.data, function(d) { return +((d[upperBound]) ? d[upperBound] : 0); })])
          .range([ height, 0 ]);
        svg.append("g")
          .call(d3.axisLeft(y));
        svg.append("path")
          .datum(this.data)
          .attr("fill", "#cce5df")
          .attr("stroke", "none")
          .attr("d", d3.area()
            .x(function(d) { return x(d.date) })
            .y0(function(d) { return y(((d[upperBound]) ? d[upperBound] : 0)) })
            .y1(function(d) { return y(((d[lowerBound]) ? d[lowerBound] : 0)) })
            )
        svg.append("path")
          .datum(this.data)
          .attr("fill", "none")
          .attr("stroke", "#41b883")
          .attr("stroke-width", 1.5)
          .attr("d", d3.line()
            .x(function(d) { return x(d.date) })
            .y(function(d) { return y(((d[metric]) ? d[metric] : 0)) })
            )
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        this.create(this.metric, this.lowerBound, this.upperBound);
      },
    }
  },
};
</script>
