<template>
  <div>
    <confirmationPopUp :show="showConfirmationModal"
                      :message="`Sure you want to run workflow ${workflow.name}?`"
                      :confirmationButtonType="'success'"
                      :confirmationButton="'Yes, run!'"
                      @confirmed="runWorkflow(workflow.name)"
                      :cancellationButton="'No'"
                      :cancellationButtonType="'default'"
                      @cancelled="showConfirmationModal=false;"/>
    <confirmationPopUp :show="showConfirmationModal"
                      :message="`Run workflow ${workflow.name}?`"
                      :confirmationButtonType="'success'"
                      :confirmationButton="'Yes, run!'"
                      @confirmed="runWorkflow(workflow.name)"
                      :cancellationButton="'No'"
                      :cancellationButtonType="'default'"
                      @cancelled="showConfirmationModal=false;"/>
    <confirmationPopUp :show="showRunInDevModeModal"
                      :message="`Run workflow ${workflow.name} in development mode?`"
                      :confirmationButtonType="'success'"
                      :confirmationButton="'Yes, run!'"
                      @confirmed="runWorkflow(workflow.name)"
                      :cancellationButton="'No'"
                      :cancellationButtonType="'default'"
                      @cancelled="showRunInDevModeModal=false;"/>
      <modalWorkflow :show="showSettingsModal"
                      :workflow="workflow"
                      :users="users"
                      @close="showSettingsModal=false;"
                      @saveWorkflow="saveWorkflow(workflow);"
      />
      <modalRuntimeLog :show="showRuntimeLog"
                      :runtimeLog="runtimeLog"
                      @close="showRuntimeLog=false;"
      />
    <div class="container">
      <div class="row no-gutters">
        <div class="col">
          <div class="row no-gutters">
            <div class="col">
              <h1 class="text-white">
                {{workflow.name.replace(workflow.business+'_','')}}</h1>
            </div>
            <div class="col-4">
              <div class="row no-gutters">
                <div class="col">
                  <i class="ni ni-button-play text-green"
                  @click="showConfirmationModal=true;"/>
                </div>
                <div class="col">
                  <i class="ni ni-vector text-warning"
                  @click="showRunInDevModeModal=true"/>
                </div>
                <div class="col">
                  <i class="ni ni-button-pause text-red"/>
                </div>
                <div class="col">
                  <i class="ni ni-settings-gear-65 text-grey"
                    @click="showSettingsModal=true"/>
                </div>
                <div class="col">
                  <a :href="`mailto:support@chron.cloud?subject=Regarding workflow: ${workflow.name.toLowerCase()}&body=Link to workflow: https://app.chron.cloud/workflows/${workflow.name.toLowerCase()}`">
                  <i class="ni ni-chat-round text-blue"/>
                  </a>
                </div>
             </div>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-4">
              <h5 class="text-white">SCHEDULE:<br/> {{(workflow.active) ? `ACTIVE | ${workflow.schedule}` : `PAUSED`}}</h5>
            </div>
            <div class="col-4">
              <h5 class="text-white">LAST RUN:<br/> {{(workflow.was_successful_last_time) ? 'SUCCESSFULL' : 'FAILED'}} | {{workflow.last_run}}</h5>
            </div>
            <div class="col-4">
              <h5 class="text-white">NEXT RUN:<br/> {{workflow.next_run}}</h5>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="col">
          <div class="row no-gutters">
            <div class="card card-stats">
              <div class="card-header">
                <h5 class="card-title text-uppercase text-muted mb-0">Description</h5>
              </div>
              <div class="card-body">
                <small>{{workflow.description}}</small>
              </div>
            </div>
          </div>
          <br/>
          <div class="row no-gutters">
            <div class="col">
              <div class="card">
                <div class="table-responsive">
              <base-table thead-classes="thead-light"
                :data="workflow.datasources">
              <template slot="columns"  class="d-none d-lg-table-cell">
                <th class="d-lg-table-cell">Datasource | Last time for operation</th>
              </template>
              <template slot-scope="{row}">
                <td>
                  <div class="col">
                    <div class="row">
                      {{row.integration_name}}
                    </div>
                    <div class="row">
                      <div class="col">
                      <h6 v-for="(op, index) in row.methods" :key="op.method+index">
                        {{op.method}} | {{op.timestamp}} <br/>
                      </h6>
                      </div>
                    </div>
                  </div>
                </td>
              </template>
              </base-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <br class="d-lg-none"/>
          <div class="card" style="height: 100vh;">
            <div class="table-responsive">
              <base-table thead-classes="thead-light"
                  :data="workflow.logs">
                <template slot="columns"  class="d-none d-lg-table-cell">
                  <th class="d-lg-table-cell">Timestamp</th>
                  <th class="d-lg-table-cell">Method</th>
                  <th></th>
                </template>
                <template slot-scope="{row}">
                  <td class="d-lg-table-cell (row.success)?'text-success':'text-danger'">
                    {{row.date}}
                  </td>
                  <td class="d-lg-table-cell">
                    <h6 v-for="m in row.methods" :key="m.method_name"
                                                  :class="(m.success)?'text-success':'text-danger'">
                      <div  >{{m.method_name}}</div>
                    </h6>
                  </td>
                  <td>
                  <i class="ni ni-zoom-split-in"
                    @click="setRuntimeLog(row)"
                  ></i>
                  </td>
                </template>
              </base-table>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div class="row no-gutters" v-if="showConsole">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row no-gutters">

              <div class="col-1">
                <input type="range"
                                :min="workflow.commit_list[0]"
                                :max="workflow.commit_list.slice(-1).pop()"
                                step="2000"
                                list="commits"
                                @change="setLogKey($event)"
                                orient="vertical">
                <datalist id="commits">
                    <option v-for="l in workflow.commit_list" :key="l" :value="l"/>

                </datalist>
              </div>
              <div class="col-11">
                <div class="row no-gutters">
                  <h3>
                    {{workflow.commits[logKey].date}} | <a :href="workflow.commits[logKey].url">{{workflow.commits[logKey].sha.substring(0,7)}} - {{workflow.commits[logKey].message}}</a>
                  </h3>
                </div>
                <div class="row no-gutters">
                  <div class="col-5">
                      <div class="card code" >
                        <div class="card-body"  style="height:100vh;">
                          {{workflow.commits[logKey].changes}}
                        </div>
                      </div>
                  </div>
                  <div class="col-7">
                      <div class="card console" style="height:100vh;">
                        <div class="card-body">
                          <body class="console" v-if="!workflow.commits[logKey].errors.length">Silence is bliss.</body>
                          <body class="console"
                            v-for="e in workflow.commits[logKey].errors" :key="e">{{e.trace}}</body>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from 'axios';
import config from '@/config';
import ConfirmationPopUp from '@/components/ConfirmationPopUp.vue'
import ModalWorkflow from '@/components/ModalWorkflow.vue';
import ModalRuntimeLog from '@/components/ModalRuntimeLog.vue';
import Workflow from '@/data-models/workflow.json';
import RuntimeLog from '@/data-models/runtimeLog.json';

export default {
  components: {
    confirmationPopUp: ConfirmationPopUp,
    modalWorkflow: ModalWorkflow,
    modalRuntimeLog: ModalRuntimeLog,
  },
  props:['workflow_id', 'runWorkflow'],
  data() {
    return {
      logKey: 1650405600,
      showConfirmationModal: false,
      showRunInDevModeModal: false,
      showRuntimeLog: false,
      showSettingsModal:false,
      showConsole: false,
      users: [],
      runtimeLog: RuntimeLog,
      workflow:Workflow,
    };
  },
  methods: {
    getWorkflow(workflow_id){
      var path = `${config.backend}${config.workflows}/${workflow_id}`
      axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
        .then((result) => {
          var tempCommits = []

          this.workflow = result.data;
          console.log(this.workflow.commit_list[0])
          if(this.workflow.commit_list[0]!="000"){
            this.showConsole = true;
          }
          this.logKey = this.workflow.commit_list.slice(-1).pop()

          })
        .catch((error) => {
          console.log(error);
          // eslint-disable-next-line

        });
    },
    setRuntimeLog(runtimeLog){
      console.log(runtimeLog);
      this.runtimeLog = runtimeLog;
      this.showRuntimeLog = true
    },
    setLogKey(event){
        var closest = this.workflow.commit_list.reduce(function(prev, curr) {
          return (Math.abs(curr - event.target.value) < Math.abs(prev - event.target.value) ? curr : prev);
        });
         this.logKey = closest


    },
  },
  mounted() {
    this.getWorkflow(this.$route.params.workflow);
  },
  created() {
  },
  watch: {

  }
};
</script>
<style>

input[type=range][orient=vertical]
{
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* Chromium */
    width: 8px;
    height: 100%;
    padding: 0 5px;
}
.code {
  font-family: Segoe UI;
  font-size: 11px;
}
.console {
  color: white;
  background-color: black!important;
  font-size: 11px;
  font-family: Courier;
}
</style>


