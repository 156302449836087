<template>
  <div class="col">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
      <br>
      <br>
      <br>
      <br>
      <div class="card bg-secondary shadow border-0">
        <div class="card-body" v-if="passwordChanged">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Password successfully changed. <a href="/login"> Click here to login</a></span>
          </div>
        </div>
        <div class="card-body" v-if="!tokenValidated && !passwordChanged">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Link has expired. Contact <a href="mailto:support@chron.cloud">Support or <a href="/login">request new link</a></a></span>
          </div>
        </div>
        <div class="card-body px-lg-5 py-lg-5" v-if="tokenValidated  && !passwordChanged">
          <form role="form">
              <base-alert v-if="showMessage" type="danger" :message="message">
                <template>
                  <slot type="type">{{message.text}}</slot>
                </template>
              </base-alert>
              <base-input class="input-group-alternative mb-3"
                          placeholder="New password"
                          type="password"
                          addon-left-icon="ni ni-lock-circle-open"
                          v-on:keyup.enter.native="saveNewPassword()"
                          @change="checkPassword()"
                          v-model="password">
              </base-input>

              <base-input class="input-group-alternative"
                          placeholder="Re-enter new password"
                          type="password"
                          addon-left-icon="ni ni-lock-circle-open"
                          v-on:keyup.enter.native="saveNewPassword()"
                          @change="checkPassword()"
                          v-model="repeatPassword">
              </base-input>
              <div class="row mt-3">
                  <div class="col-6 text-left">
                      <small>{{passwordMessage}}</small>
                  </div>
              </div>
              <div class="text-center">
                  <base-button type="primary" class="my-4" @click.native="saveNewPassword()" >Save</base-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import config from '@/config';

export default {
  data() {
    return {
      email: '',
      password: '',
      repeatPassword: '',
      passwordMessage: ' ',
      passwordChanged: false,
      allowSend: false,
      showMessage: false,
      tokenValidated: false,
      user_id:null,
      token: null,
      message: {
        variant: 'danger',
        text: 'Invalid email and/or password',
      },
    };
  },
  components: {
  },
  methods: {
    checkPassword(){
      // eslint-disable-next-line
      var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      // eslint-disable-next-line
      var formatNumbers = /123456789/;
      if(!format.test(this.password)){
        this.passwordMessage = "Password must contain a special character"
        this.allowSend = false;
      } else {
        if(!/\d/.test(this.password)){
          this.passwordMessage = "Password must contain a number"
          this.allowSend = false;
        }else{
          if(!/[A-Z]/.test(this.password)){
          this.passwordMessage = "Password must contain an uppercase character"
          this.allowSend = false;
          }else{
            if(this.password!=this.repeatPassword){
              this.passwordMessage = "Password does not match"
              this.allowSend = false;
            } else{
              this.passwordMessage = "Everything looks fine!";
              this.allowSend = true;
            }
          }

        }
      }
    },
    validateToken(){
      if(this.$router.history.current.query.token){
        const token = this.$router.history.current.query.token;
        const path = `${config.backend}/status`;
        axios.get(path, {headers:{ Authorization:`${token}`}})
        .then((result) => {
          this.token = this.$router.history.current.query.token;
          this.email = result.data.email;
          this.tokenValidated=true;

        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401) {
            this.authenticated = false;
          }
        });
      }
    },
    saveNewPassword(){
      const path = `${config.backend}${config.forgotPassword}/${this.email}`;
        axios.put(path, {password:this.password}, {headers:{ Authorization:`${this.token}`}})
          .then((result) => {
            console.log(result.data);
            this.passwordChanged = true;
          })
          .catch((error) => {
            // eslint-disable-next-line
            if (error.response.status === 401) {
              this.showLoginMessage = true;
            }
          });
    },
  },
  mounted() {
   this.validateToken();
  },
};
</script>
<style>
.modal-dialog {
}
.modal-backdrop
{
    opacity:1 !important;
    width: 100%;
    height: 100%;
}
.close
{
  display: none;
}
</style>
