<template>
<modal :show="show" v-on:close="$emit('closeMe')" modal-classes="modal-dialog-centered modal-lg">
    <div class="row">
      <div class="col">
        <h3>{{workflow.name}}</h3>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-4">
        <div class="row">
          <div class="col">
            Active
          </div>
          <div class="col">
            <label class="custom-toggle">
              <input type="checkbox" v-model="workflow.active">
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col">
            Notify people:
          </div>
          <div class="col">
            <label class="custom-toggle">
              <input type="checkbox"
                  v-model="notSilentPlaceholder"
                  @click="changeSilent(notSilentPlaceholder);">
              <span class="custom-toggle-slider rounded-circle"></span>
            </label>
          </div>
        </div>
        <br/>
        <div class="row" v-if="workflow.active">
          <div class="col">
            Frequency:
          </div>
          <div class="col">
            <select v-model="workflow.schedule" :value="workflow.schedule">
              <option v-for="sched in Object.keys(schedules)"
                  :key="sched"
                  @click="workflow.schedule=sched;"
                  :value="sched" >
                {{sched}}
              </option>
            </select>
          </div>
        </div>
        <br/>
      </div>
      <div class="col" v-if="notSilentPlaceholder">
        <div class="row">
          <div class="card">
            <div class="table-responsive">
              <base-table thead-classes="thead-light"
                :data="users">
                <template slot="columns"  class="d-none d-lg-table-cell">
                  <th class="d-lg-table-cell">Person</th>
                  <th class="d-lg-table-cell">Notify WHEN</th>
                  <th class="d-lg-table-cell">Mail</th>
                  <th class="d-lg-table-cell">SMS</th>
                </template>
                <template slot-scope="{row}">
                  <td scope="row" >
                      {{row.name}}
                  </td>
                  <td scope="row"  class="d-md-table-cell">
                    <select v-model="row.workflow_notifications.when">
                      <option v-for="sched in notifyWhen"
                          :key="sched"
                          :value="sched">
                        {{sched}}
                      </option>
                    </select>
                  </td>
                  <td scope="row" class="d-md-table-cell">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox"
                               class="custom-control-input"
                               v-model="row.workflow_notifications.email"
                                :id="row.name+'mailCheck'">
                      <label class="custom-control-label" :for="row.name+'mailCheck'"></label>
                    </div>
                  </td>
                  <td scope="row" class="d-md-table-cell">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox"
                              v-model="row.workflow_notifications.sms"
                              class="custom-control-input"
                              :id="row.name+'smsCheck'">
                      <label class="custom-control-label" :for="row.name+'smsCheck'"></label>
                    </div>
                  </td>
                </template>
              </base-table>
            </div>
            </div>
        </div>
      </div>
    </div>
    <br/>
    <template slot="footer">
      <base-button type="secondary" class="ml-auto" @click="$emit('close')">
        Close
      </base-button>
      <base-button type="success"  @click="$emit('saveWorkflow')">
        Save
      </base-button>
    </template>
    <form>

</form>
</modal>
</template>
<script>
  import Schedules from '@/data-models/schedules.json';

  export default {
    components: {
    },
    props:['workflow', 'show', 'users'],
    data() {
      return {
        val: true,
        notifyWhen: ['NEVER','FAILURE','SUCCESS','ALWAYS'],
        notSilentPlaceholder: true,
        schedules: Schedules,
      };
    },
    methods: {

      changeSilent(silent){
        this.notSilentPlaceholder = !silent;
        this.workflow.silent = silent;
        console.log(this.workflow.silent);
      },

    },
    mounted() {
      this.changeSilent(this.workflow.silent);
      console.log(this.workflow);
    },
    computed(){
    },
  };
</script>
<style></style>
