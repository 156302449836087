<template>
<div id="app">
  <router-view
    v-if="$route.name=='Login' || $route.name=='Forgot Password' || $route.name=='New User' "/>
  <mainContent v-else :show="true"/>
</div>
</template>
<script>
  import MainContent from '@/main/MainContent.vue';

  export default {
    components: {
      mainContent: MainContent,
    },
    data() {
      return {
        authenticated: false,
      };
    },
    methods: {
    },
    mounted() {
      this.authenticated = localStorage.authenticated;
      console.log(this.authenticated);
    },
  };
</script>
<style lang="scss">
</style>
