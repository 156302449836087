<template>
  <div :id="'bar-chart'+'-'+direction+'-'+noGraph"/>
</template>
<script>
import * as d3 from 'd3';
/* eslint-disable */
export default {
  data() {
    return {
    };
  },
  props: ['data',
    'enableMetricAxis',
    'direction',
    'noGraph',
    'size',
    'dimension',
    'metric',
    'chartSize',
    'chartMargin'],
  methods: {
    create(dimension, metric, chartSize, chartMargin) {
      const width = chartSize.width - chartMargin.left - chartMargin.right;
      const height = chartSize.height - chartMargin.top - chartMargin.bottom;
      // append the svg object to the body of the page
      // eslint-disable-next-line
      d3.select('#bar-chart' + '-' + this.direction + '-' + this.noGraph).selectAll('*').remove();
      // eslint-disable-next-line
      var svg = d3.select('#bar-chart' + '-'+this.direction + '-' + this.noGraph)
        .append('svg')
        .attr('height', chartSize.height.toString())
        .attr('width', "100%")
        .attr('viewBox','0 0 '+chartSize.width.toString()+' '+chartSize.height.toString())
        .attr('preserveAspectRatio', "none")
        .append('g')
          .attr('transform',
                'translate(' + chartMargin.left + ',' + chartMargin.top + ')');
            // create a tooltip
      var tooltip = d3.select("#heatmap")
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("border", "solid")
          .style("border-width", "1px")
          .style("padding", "5px")

        // Three function that change the tooltip when user hover / move / leave a cell
        var mouseover = function(d) {
          tooltip.style("opacity", 1)
        }
        var mousemove = function(d){
                              tooltip
                                .html('<b>'+d.target.attributes[dimension].value +
                                        '</b>: ' + d.target.attributes[metric].value)
                                .style("left", d3.pointer(d)[0] + "px")
                                .style("top", d3.pointer(d)[1]+ "px")
                            }
        var mouseleave = function(d) {
          tooltip.style("opacity", 0)
        }
      if (this.direction === 'horizontal') {
        // Add X axis
        var x = d3.scaleLinear()
          .domain([0, d3.max(this.data, function(d) { return +((d[metric]) ? d[metric] : 0); })])
          .range([0, width]);
        if(this.enableMetricAxis){
          svg.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(x))
            .selectAll('text')
            .attr('transform', 'translate(-10,0)rotate(-45)')
            .style('text-anchor', 'end');
        }
        // Y axis
        var y = d3.scaleBand()
          .range([ 0, height ])
          .domain(this.data.map(function(d) { return d[dimension]; }))
          .padding(.1);
        svg.append('g')
          .call(d3.axisLeft(y))
        //Bars
        svg.selectAll('bar-chart'+'-'+this.direction+'-'+this.noGraph)
          .data(this.data)
          .enter()
          .append('rect')
          .attr('y', function(d) { return y(d[dimension]); })
          .attr('height', y.bandwidth() )
          .on('mouseover', mouseover)
          .on('mousemove',mousemove)
          .on('mouseleave', mouseleave);
        svg.selectAll('rect')
          .transition()
          .duration(2000)
          .attr('x', x(0) )
          .attr('width', function(d) { return x(((d[metric]) ? d[metric] : 0)); })
          .attr('fill', '#41b883')
          .attr(metric, function(d) { return d[metric] })
          .attr(dimension, function(d) { return d[dimension] })

      } else {
      // X axis
        var x = d3.scaleBand()
          .range([ 0, width ])
          .domain(this.data.map(function(d) { return d[dimension]; }))
          .padding(0.2);
        svg.append('g')
          .attr('transform', 'translate(0,' + height + ')')
          .call(d3.axisBottom(x))
          .selectAll('text')
          .attr('transform', 'translate(-10,0)rotate(-45)')
          .style('text-anchor', 'end');
        // Add Y axis
        var y = d3.scaleLinear()
          .domain([0, d3.max(this.data, function(d) { return +((d[metric]) ? d[metric] : 0) ; })])
          .range([height, 0]);
        if(this.enableMetricAxis){
          svg.append('g')
            .call(d3.axisLeft(y));
        }
        // Bars
        svg.selectAll('bar-chart'+'-'+this.direction+'-'+this.noGraph)
          .data(this.data)
          .enter()
          .append('rect')
          .attr('x', function(d) { return x(d[dimension]); })
          .attr('width', x.bandwidth())
          .attr('fill', '#41b883')
          // no bar at the beginning thus:
          .attr('height', function(d) { return height - y(0); }) // always equal to 0
          .attr('y', function(d) { return y(0); })
          .attr(metric, function(d) { return d[metric] })
          .attr(dimension, function(d) { return d[dimension] })
          .on('mouseover', mouseover)
          .on('mousemove',mousemove)
          .on('mouseleave', mouseleave);
        svg.selectAll('rect')
          .transition()
          .duration(2000)
          .attr('y', function(d) { return y(((d[metric]) ? d[metric] : 0)); })
          .attr('height', function(d) { return height - y(((d[metric]) ? d[metric] : 0)); })
          .delay(function (d, i) { return (i * 100); });
      }
    },
  },
  computed: {
  },
  mounted() {
    this.svgWidth = document.getElementById('bar-chart' + '-' + this.direction + '-' + this.noGraph).offsetWidth * 0.75;
    this.create(this.dimension, this.metric, this.chartSize, this.chartMargin);
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal) {
        this.create(this.dimension, this.metric, this.chartSize, this.chartMargin);
      },
    },
  },
};
</script>
