<template>
  <div>
    <base-header type="gradient-default" class="pb-6 pb-8 pt-5 pt-md-7">
      <modalWorkflow :show="showSettingsModal"
                      :workflow="workflow"
                      :users="users"
                      @close="showSettingsModal=false;"
                      @saveWorkflow="saveWorkflow(workflow);"
      />

      <router-view v-if="this.$router.history.current.params.workflow"/>
      <div class="container"  v-else>
        <div class="row no-gutters">

          <div class="col-xl-12">
              <div v-if="showAlert" :class="'alert alert-'+msgType" role="alert">
                  {{msg}}
              </div>

            <div class="card">
              <div class="table-responsive">
                <base-table thead-classes="thead-light"
                  :data="workflows">
                  <template slot="columns"  class="d-none d-lg-table-cell">

                    <th class="d-lg-table-cell">Workflow</th>
                    <th class="d-none d-sm-table-cell">Schedule | Next run</th>
                    <th class="d-none d-md-table-cell">Success-rate</th>
                    <th class="d-table-cell"/>
                  </template>
                  <template slot-scope="{row}">
                    <td scope="row">
                      <div class="row">
                        <h4><a :href="'workflows/'+row.name">{{row.name.replace(row.business.toUpperCase()+"_","")}}</a></h4>
                      </div>
                      <br class="row d-sm-none"/>
                      <div class="row">
                        <h5>LAST RUN: </h5>{{(row.was_successful_last_time)?'SUCCESSFUL':'FAILED'}} | {{row.last_run}}
                      </div>
                      <br class="row d-sm-none"/>
                      <div class="row d-sm-none">
                        <h5>SCHEDULE | NEXT RUN: </h5>{{row.schedule}} | {{row.next_run}}
                      </div>

                    </td>
                    <td scope="row" class="d-none d-sm-table-cell">
                      <div class="row">
                        <br/>
                      </div>
                      <div class="row" v-if="row.active && row.schedule !='NEVER'">
                        {{row.schedule}} | {{row.next_run}}
                      </div>
                    </td>
                    <td scope="row" class="d-none d-md-table-cell">
                      <div class="row">
                        <br/>
                      </div>
                      <div class="row">
                        {{row.success_rate}} %
                      </div>
                    </td>
                    <td scope="row" >
                      <div class="row">
                        <br/>
                      </div>
                      <div class="row">
                        <div class="col">
                          <i class="ni ni-button-play text-green"
                          @click="showRunConfirmation(row.name)"/>
                        </div>
                        <div class="col">
                          <i class="ni ni-settings-gear-65 text-grey"
                          @click="changeWorkflow(row)"/>
                        </div>
                     </div>
                    </td>
                  </template>
                </base-table>
              </div>
            </div>
          </div>
        </div>
        <br/>
         <div class="row">
        <div class="col-xl-7">
          <div class="card">
            <div class="table-responsive">
              <base-table thead-classes="thead-light"
                :data="workflows">
              <template slot="columns"  class="d-none d-lg-table-cell">
                <th class="d-lg-table-cell">Workflow</th>
                <th class="d-none d-lg-table-cell">Method</th>
              </template>
              <template slot-scope="{row}">
                <td>
                  <div class="col">
                    <div class="row">
                      {{row.name.replace(row.business.toUpperCase()+"_","")}}
                    </div>
                    <div class="row d-md-none">
                      <div class="col">
                        <div v-for="(m, index) in row.methods" :key="index+m">
                          <h6 v-if="m.ran"
                            :class="(m.success)?'text-success':'text-danger'">
                            {{m.method_name}}
                          </h6>
                          <h6 v-else> {{m.method_name}}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="d-none d-lg-table-cell">
                  <div v-for="(m, index) in row.methods" :key="index+m">
                        <h6 v-if="m.ran"
                          :class="(m.success)?'text-success':'text-danger'">
                          {{m.method_name}}
                        </h6>
                        <h6 v-else> {{m.method_name}}
                        </h6>
                      </div>
                </td>
              </template>
              </base-table>
            </div>
          </div>
        </div>

        <div class="col-xl-5">
        <br class="d-lg-none"/>
          <div class="card">
            <div class="table-responsive">
              <base-table thead-classes="thead-light"
                :data="datasources">
              <template slot="columns"  class="d-none d-lg-table-cell">
                <th class="d-lg-table-cell">Datasource | Last time for operation</th>
              </template>
              <template slot-scope="{row}">
                <td>
                  <div class="col">
                    <div class="row">
                      {{row.integration_name}}
                    </div>
                    <div class="row">
                      <div class="col">
                      <h6 v-for="(op, index) in row.methods" :key="op.method+index">
                        {{op.method}} | {{op.timestamp}} <br/>
                      </h6>
                      </div>
                    </div>
                  </div>
                </td>
              </template>
              </base-table>
            </div>
          </div>
        </div>
      </div>
      <confirmationPopUp :show="showRunConfirmationModal"
                          :message="`Sure you want to run workflow ${workflowName}?`"
                          :confirmationButtonType="'success'"
                          :confirmationButton="'Yes, run!'"
                          @confirmed="runWorkflow(workflowName)"
                          :cancellationButton="'No'"
                          :cancellationButtonType="'default'"
                          @cancelled="showRunConfirmationModal=false;"/>

      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from 'axios';
import config from '@/config';
import ConfirmationPopUp from '@/components/ConfirmationPopUp.vue';
import ModalWorkflow from '@/components/ModalWorkflow.vue';
export default {
  components: {
    confirmationPopUp: ConfirmationPopUp,
    modalWorkflow:ModalWorkflow,
  },
  props:[],
  data() {
    return {
      showAlert: false,
      showRunConfirmationModal: false,
      showSettingsModal: false,
      msgType: 'danger',
      msg: 'haha',
      users: [],
      workflows:[],
      datasources:{},
      modalMessage: '',
      workflowName: '',
      workflow: {},
    };
  },
  methods: {
    getWorkflows(){
      var path = `${config.backend}${config.workflows}`
      axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
        .then((result) => {
          this.workflows = result.data.workflows;
          this.datasources = result.data.datasources;
        })
        .catch((error) => {
          console.log(error);
          // eslint-disable-next-line

        });
    },
    showRunConfirmation(workflowName){
      this.workflowName = workflowName;
      this.showRunConfirmationModal = true;
    },
    changeWorkflow(workflow){
      this.workflow = workflow;
      this.getUsers(workflow.name);
      this.showSettingsModal = true;
    },
    getLogs(){
      var path = `${config.backend}${config.logs}`
      axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
        .then((result) => {
          this.logs = result.data.logs;
        })
        .catch((error) => {
          console.log(error);
          // eslint-disable-next-line

        });
      setTimeout(() => {  this.showAlert=false; }, 3000);
    },
    runWorkflow(name){
      this.showRunConfirmationModal = false;
      var path = `${config.backend}${config.workflows}/${name}`
      this.showSpinner = true;
      axios.post(path,{}, {headers:{Authorization:`${localStorage.Authorization}`}})
        .then((result) => {
          this.result = result.data.workflows;
          this.showSpinner = false;
          this.getWorkflows();
          this.showAlert= true;
          this.msgType = 'success';
          this.msg = `WORKFLOW ${name} RAN SUCCESSFULLY`
        })
        .catch((error) => {
          console.log(error);
          // eslint-disable-next-line
          this.showSpinner = false;
          this.showAlert= true;
          this.msgType = 'danger';
          this.msg = `WORKFLOW ${name} FAILED`
          this.getWorkflows();
        });
        setTimeout(() => {  this.showAlert=false; }, 3000);
    },
    saveUserSettings(workflowName, users){
      var path = `${config.backend}${config.workflow_notifications}/${workflowName}/users`
      var requestBody = {'users': users}
      axios.put(path,users, {headers:{Authorization:`${localStorage.Authorization}`}})
            .then((result) => {
              this.getWorkflows();
            })
            .catch((error) => {
            });
    },
    saveWorkflow(workflow){
      this.showSettingsModal = false;
        var path = `${config.backend}${config.workflows}/${workflow.name}`
        axios.put(path,workflow, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.getWorkflows();
            this.showAlert= true;
            this.msgType = 'success';
            this.msg = `CHANGES TO WORKFLOW ${workflow.name} WAS SAVED SUCCESSFULLY`
            this.saveUserSettings(workflow._id,this.users)
          })
          .catch((error) => {
            console.log(error);
            // eslint-disable-next-line
            this.showAlert= true;
            this.msgType = 'danger';
            this.msg = `SAVING WORKFLOW ${name} FAILED`
            //this.getWorkflows();
          });
          setTimeout(() => {  this.showAlert=false; }, 3000);
    },

    getUsers(workflow_id){
        var path = `${config.backend}${config.workflow_notifications}/${workflow_id}/users`
        axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.users = result.data.users;
          })
          .catch((error) => {
            console.log(error);
            // eslint-disable-next-line

          });
      },
  },
  mounted() {
    this.getWorkflows();
  },
  created() {
  },
  watch: {
  }
};
</script>
<style>
</style>
