export default {
  backend: process.env.VUE_APP_API_URL,
  activity: '/logs/activity',
  businesses: '/businesses',
  commits: '/logs/commits',
  credentials: '/credentials',
  forgotPassword: '/forgot_password',
  login:'/login',
  logs: '/logs',
  sendInvite: '/send_invite',
  users: '/users',
  workflow_notifications: '/workflow_notifications',
  workflows: '/workflows',
};

