<template>
    <div>
        <!--Totals-->
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Revenue"
                                type="gradient-red"
                                :sub-title="Intl.NumberFormat('da-DK').format(data.revenue)"
                                icon="ni ni-active-40"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer"  v-if="data.delta_revenue!=0">
                            <span :class="'text-'+(data.delta_revenue>0?'success':'danger')+' mr-2'">
                                <i :class="'fa fa-arrow-'+(data.delta_revenue>0?'up':'down')"></i> {{data.delta_revenue}} %</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Cost Price"
                                type="gradient-orange"
                                :sub-title="Intl.NumberFormat('da-DK').format(data.costPrice)"
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer" v-if="data.delta_cost_price!=0">
                            <span :class="'text-'+(data.delta_cost_price>0?'success':'danger')+' mr-2'">
                                <i :class="'fa fa-arrow-'+(data.delta_cost_price>0?'up':'down')"></i> {{data.delta_cost_price}} %</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Units Sold"
                                type="gradient-green"
                                :sub-title="Intl.NumberFormat('da-DK').format(data.productsSold)"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0"
                    >
                        <template slot="footer" v-if="data.delta_amount_of_products!=0">
                            <span :class="'text-'+(data.delta_amount_of_products>0?'success':'danger')+' mr-2'">
                                <i :class="'fa fa-arrow-'+(data.delta_amount_of_products>0?'up':'down')"></i> {{data.delta_amount_of_products}} %</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="# in Cat."
                                type="gradient-info"
                                :sub-title="data.rankInCategory.toString()"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer" v-if="false">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> N/A</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
            </div>
            <br>
            <!--Charts-->
            <div class="row">
                <div class="col-xl-6 mb-5 mb-xl-0">
                    <div class="row">
                        <div class="col">
                            <card header-classes="bg-transparent">
                                <div slot="header" class="row align-items-center">
                                    <div class="col">
                                       <h5 class="h3 mb-0">{{(product)?'Complimentary Products':'Products'}}</h5>
                                    </div>
                                </div>
                                    <barChart :direction="'horizontal'"
                                      :data="data.complimentaryProducts"
                                      :dimension="'product'"
                                      :chartSize="productsChartSize"
                                      :chartMargin="productsChartMargin"
                                      :metric="'amount_of_products'"
                                      :noGraph="3"/>

                            </card>
                        </div>
                    </div>
                    <br>
                </div>
                <div class="col-xl-6">
                    <!--Productgroup-->
                    <div class="row">
                        <div class="col">
                            <card header-classes="bg-transparent">
                                <div slot="header" class="row align-items-center">
                                    <div class="col">
                                        <h6 class="text-uppercase text-muted ls-1 mb-1">{{product}}</h6>
                                        <h5 class="h3 mb-0">Amount Sold</h5>
                                    </div>

                                </div>
                                <lineChart
                                        :height="350"
                                        :data="data.timeline"
                                        :metric="'amount_of_products'"
                                        :noGraph="2"/>
                            </card>
                        </div>
                    </div>

                    <br>
                    <!--Products-->
                     <div class="row">
                         <div class="col">
                             <card header-classes="bg-transparent">
                                  <div slot="header" class="row align-items-center">
                                      <div class="col">
                                          <h6 class="text-uppercase text-muted ls-1 mb-1">{{product}}</h6>
                                          <h5 class="h3 mb-0">Amount sold pr. weekday & hour</h5>
                                      </div>
                                  </div>
                                <heatmap :data="data.heatmap"
                                  :yAxisDimension="'weekday_name'"
                                  :xAxisDimension="'hour'"
                                  :metric="'amount_of_products'"
                                  :id="'product-analysis'"/>
                             </card>
                        </div>
                    </div>
                </div>
            </div>
            <!--Table-->
            <div class="row">
                 <div class="col-xl-6">
                    <div class="card">
                        <div class="card-header border-0">
                          <div class="row align-items-center">
                            <div class="col">
                              <h3 class="mb-0">Most popular combos</h3>
                            </div>
                          </div>
                        </div>

                        <div class="table-responsive">
                          <base-table thead-classes="thead-light"
                                      :data="data.mostPopularCombos">
                            <template slot="columns">
                            </template>

                            <template slot-scope="{row}">
                              <td>
                                 {{row.products_text}}
                              </td>
                            </template>
                          </base-table>
                        </div>
                    </div>
                </div>
                 <div class="col-xl-6">
                    <div class="card">
                        <div class="card-header border-0">
                          <div class="row align-items-center">
                            <div class="col">
                              <h3 class="mb-0">Ingredients</h3>
                            </div>
                          </div>
                        </div>

                        <div class="table-responsive">
                          <base-table thead-classes="thead-light"
                                      :data="data.ingredients">
                            <template slot="columns">
                            </template>

                            <template slot-scope="{row}">
                              <td>
                                 {{row.products_text}}
                              </td>
                            </template>
                          </base-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  // Charts
  import LineChart from '@/components/Charts/LineChart.vue';
  import BarChart from '@/components/Charts/BarChart.vue';
  import Heatmap from '@/components/Charts/Heatmap.vue';

  // Tables

  export default {
    components: {
      lineChart: LineChart,
      barChart: BarChart,
      heatmap: Heatmap,
    },
    data() {
      return {
          productGroupsChartSize: {width: 400, height: 350},
          productGroupsChartMargin: {top: 10, right: 5, bottom: 40, left: 5},
          productsChartSize: {width: 400, height: 860},
          productsChartMargin: {top: 20, right: 30, bottom: 40, left: 150},

      };
    },
    props:['data', 'product'],
    methods: {

    },
    mounted() {
    }
  };
</script>
<style></style>
