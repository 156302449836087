<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
        <div class="container-fluid">

            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <router-link class="navbar-brand" to="/">
                <img :src="logo" class="navbar-brand-img" alt="...">
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                    <base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link" href="#" role="button">
                            <div class="media align-items-center">
                              <span class="avatar avatar-sm rounded-circle">
                                {{userName.includes(' ') ? userName.split(' ')[0][0] + userName.split(' ')[1][0] : userName.substring(0,2)}}
                              </span>
                            </div>
                        </a>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-single-02"></i>
                            <span>Profile</span>
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <a href="/login" @click="logOut" class="dropdown-item">
                            <i class="ni ni-user-run"></i>
                            <span>Log out</span>
                        </a>
                    </base-dropdown>
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img :src="logo">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>
                <div>
                <ul class="navbar-nav">
                    <slot name="content">
                    </slot>
                </ul>
                </div>
                <!--Divider-->
                <hr class="my-3" v-if="$slots.management"/>
                <!--Heading-->
                <div v-if="$slots.management">
                  <h6 class="navbar-heading text-muted">Business Settings</h6>
                  <!--Navigation-->
                  <ul class="navbar-nav">
                      <slot name="management">
                      </slot>
                  </ul>
                </div>
            </div>
            </div>
    </nav>
</template>
<script>
  import NavbarToggleButton from '@/components/NavbarToggleButton'

  export default {
    name: 'sidebar',
    components: {
      NavbarToggleButton
    },
    props: {
      logo: {
        type: String,
        default: 'img/brand/chronos_logo.png',
        description: 'Sidebar app logo',
      },
      userName: {
        type: String,
        default: '',
        description: 'To create user avatar',
      },
      autoClose: {
        type: Boolean,
        default: true,
        description: 'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    methods: {
      closeSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      showSidebar() {
        this.$sidebar.displaySidebar(true)
      },
      logOut() {
        localStorage.removeItem('Authorization');
        window.location = '/login';
      },
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>
