<template>
<div>
  <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-8">
    <div class="card">
      <div class="table-responsive">
        <base-table thead-classes="thead-light"
          :data="businesses">
          <template slot="columns"  class="d-none d-lg-table-cell">
            <th class="d-none d-lg-block">ID[:5]</th>
            <th class="d-lg-table-cell">Business</th>
          </template>
          <template slot-scope="{row}">
            <th scope="row" class="d-none d-lg-block">
              {{row._id.substr(row._id.length-5,row._id.length)}}
            </th>
            <td scope="row" >
                {{row.name}}
            </td>
          </template>
        </base-table>
      </div>
    </div>
  </base-header>
</div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';

  export default {
    components: {
    },
    data() {
      return {
        businesses: []
      };
    },
    methods: {

    getBusinesses(){
      var path = `${config.backend}${config.businesses}?`
      axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
        .then((result) => {
          this.businesses = result.data.businesses;
        })
        .catch((error) => {
          console.log(error);
          // eslint-disable-next-line

        });
    },
    },
    mounted() {
      this.getBusinesses()
    }
  };
</script>
<style></style>
