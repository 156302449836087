<template>
    <div>
        <!--Totals-->
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="#Customer"
                                type="gradient-red"
                                :sub-title="Intl.NumberFormat('da-DK').format(data.averageCustomers)"
                                icon="ni ni-circle-08"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Prod./Hour"
                                type="gradient-orange"
                                  :sub-title="Intl.NumberFormat('da-DK').format(data.estProductsSoldPrHour)"
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="KPI1"
                                type="gradient-green"
                                sub-title="N/A"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="KPI2"
                                type="gradient-info"
                                sub-title="N/A"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0"
                    >
                    </stats-card>
                </div>
            </div>
            <br>
            <!--Charts-->
            <div class="row">
              <div class="col-xl-6 mb-5 mb-xl-0">
                <card header-classes="bg-transparent">
                  <div slot="header" class="row align-items-center">
                    <div class="col">
                      <h5 class="h3 mb-0">Customer forecast</h5>
                    </div>
                    <div class="col">
                      <ul class="nav nav-pills justify-content-end">
                        <li class="nav-item mr-2 mr-md-0">
                          <a class="nav-link py-2 px-3"
                             href="#"
                             :class="{active: 0}">
                              <span class="d-none d-md-block">Hourly</span>
                              <span class="d-md-none">H</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link py-2 px-3"
                             href="#"
                             :class="{active: 1}">
                              <span class="d-none d-md-block">Daily</span>
                              <span class="d-md-none">D</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                    <lineConfidenceChart ref="bigChart"
                      :data="data.weekForecast"
                      :metric="'amount_of_products'"
                      :lowerBound="'lowerBound'"
                      :upperBound="'upperBound'"
                      :noGraph="1"/>
                </card>
              </div>

              <div class="col-xl-6">
                <card header-classes="bg-transparent">
                  <div slot="header" class="row align-items-center">
                    <div class="col">
                        <h5 class="h3 mb-0">Weather Forecast</h5>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <base-table thead-classes="thead-light"
                                :data="data.weatherDailyForecast">
                      <template slot="columns">
                        <th>Day</th>
                        <th class="d-none d-lg-table-cell">Description</th>
                        <th>Feels like</th>
                      </template>
                      <template slot-scope="{row}">
                        <td>
                        <img :src="'http://openweathermap.org/img/wn/'+row.weather_icon+'@2x.png'"
                                        fluid
                                        width="35"/>
                          {{row.datetime.split(',')[0]}}
                        </td>
                        <td class="d-none d-lg-table-cell">
                          {{row.weather_description}}
                        </td>
                        <td>
                          {{row.feels_like.day}}°C
                        </td>
                      </template>
                    </base-table>
                  </div>

                  </card>
              </div>
            </div>
            <!-- End charts-->

            <!--Tables-->
            <div class="row mt-5" v-if="showStockGraph">
                <div class="col-xl-6 mb-5 mb-xl-0">
                    <div class="card">
                        <div class="card-header border-0">
                              <div slot="header" class="row align-items-center">
                                    <div class="col">
                                      <h5 class="h3 mb-0">Shopping List</h5>
                                    </div>
                                    <div class="col">
                                        <ul class="nav nav-pills justify-content-end">
                                            <li class="nav-item mr-2 mr-md-0">
                                                <a class="nav-link py-2 px-3"
                                                   href="#"
                                                   :class="{active: 0}"
                                                   >
                                                    <span class="d-none d-md-block">Stock</span>
                                                    <span class="d-md-none">S</span>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link py-2 px-3"
                                                   href="#"
                                                   :class="{active: 1}"
                                                   @click.prevent="initBigChart(1)">
                                                    <span class="d-none d-md-block">Products</span>
                                                    <span class="d-md-none">P</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                        </div>
                        <div class="table-responsive">
                          <base-table thead-classes="thead-light"
                                      :data="data.productRecommendation">
                            <template slot="columns">
                              <th>Product</th>
                              <th>Amount</th>
                            </template>

                            <template slot-scope="{row}">
                              <td>
                                 {{row.product}}
                              </td>
                              <td>
                                 {{row.amount_of_products}}
                              </td>
                            </template>

                          </base-table>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="card">
                        <div class="card-header border-0">
                          <div class="row align-items-center">
                            <div class="col">
                              <h3 class="mb-0">Relevant Info</h3>
                            </div>
                          </div>
                        </div>

                        <div class="table-responsive">
                          <base-table thead-classes="thead-light"
                                      :data="tableData">
                            <template slot="columns">
                              <th>Referral</th>
                              <th>Visitors</th>
                              <th></th>
                            </template>

                            <template slot-scope="{row}">
                              <th scope="row">
                                {{row.name}}
                              </th>
                              <td>
                                {{row.visitors}}
                              </td>
                              <td>
                                <div class="d-flex align-items-center">
                                  <span class="mr-2">{{row.progress}}%</span>
                                  <base-progress :type="row.progressType"
                                                 class="pt-0"
                                                 :show-percentage="false"
                                                 :value="row.progress"
                                  />
                                </div>
                              </td>
                            </template>

                          </base-table>
                        </div>
                    </div>
                </div>
            </div>
            <!--End tables-->
        </div>

    </div>
</template>
<script>
  // Charts
  import LineConfidenceChart from '@/components/Charts/LineConfidenceChart.vue';

  // Tables

  export default {
    components: {
      lineConfidenceChart: LineConfidenceChart,
    },
    props:['data'],
    data() {
      return {
        tableData:[],
        showStockGraph: false,
      };
    },
    methods: {

    },
    mounted() {

    }
  };
</script>
<style></style>
