<template>
<modal :show="show" v-on:close="$emit('closeMe')" modal-classes="modal-dialog-centered modal-md">
    <div class="row">
      <div class="col">
        <div class="row">
          <b>Runtime ID: </b>{{runtimeLog._id}}
        </div>
        <div class="row">
          <b>Timestamp: </b>{{runtimeLog.date}}
        </div>
        <div class="row">
          <b>Successful: </b>{{runtimeLog.success}}
        </div>
        <div class="row">
          <b>Runtime: </b>{{runtimeLog.runtime}} seconds
        </div>
        <div class="row">
          <div class="card console">
            <br/>
            <div class="col-12">
              Runtime log:
              <div v-for="m in runtimeLog.methods" :key="m.method_index">
                {{m.method_index+1}}. {{m.method_name}} <br/>
                 {{m.method_output}} in {{m.method_runtime}} seconds
                  <div v-if="m.ran && !m.success">{{runtimeLog.trace}}</div>
                 <br/>
                 <br/>
              </div>
            </div>
          </div>
          <br/>
      </div>
    </div>

  </div>
    <template slot="footer">
      <base-button type="secondary" class="ml-auto" @click="$emit('close')">
        Close
      </base-button>
    </template>
    <form>

</form>
</modal>
</template>
<script>
  export default {
    components: {
    },
    props:['show','runtimeLog'],
    data() {
      return {

      };
    },
    methods: {



    },
    mounted() {

    },
  };
</script>
<style>
.console {
  color: white;
  background-color: black!important;
  font-size: 11px;
  font-family: Courier;
}
</style>

