<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{year}} <a href="https://www.google.com/maps/place/Charlie's+Bar/@55.6806817,12.5780847,17z/data=!3m1!4b1!4m5!3m4!1s0x4652531754003e41:0x715b73f3329ae554!8m2!3d55.6806817!4d12.5802734" class="font-weight-bold ml-1" target="_blank">Pilestræde33 Aps</a>
        </div>
      </div>
      <div class="col-lg-6">
        <ul class="nav nav-footer justify-content-center justify-content-lg-end">
          <li class="nav-item">
            <a href="https://www.google.com/maps/place/Charlie's+Bar/@55.6806817,12.5780847,17z/data=!3m1!4b1!4m5!3m4!1s0x4652531754003e41:0x715b73f3329ae554!8m2!3d55.6806817!4d12.5802734" class="nav-link" target="_blank">Support</a>
          </li>
          <li class="nav-item">
            <a href="https://www.google.com/maps/place/Charlie's+Bar/@55.6806817,12.5780847,17z/data=!3m1!4b1!4m5!3m4!1s0x4652531754003e41:0x715b73f3329ae554!8m2!3d55.6806817!4d12.5802734" class="nav-link" target="_blank">About Us</a>
          </li>
          <li class="nav-item">
            <router-link to="/changelog" class="nav-link">Changelog</router-link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
