<template>
    <div>
        <!--Totals-->
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Revenue"
                                type="gradient-danger"
                                :sub-title="Intl.NumberFormat('da-DK').format(data.revenue)"
                                icon="ni ni-chart-bar-32"
                                class="mb-4 mb-xl-0"
                    >

                        <template slot="footer"  v-if="data.delta_revenue!=0">
                            <span :class="'text-'+(data.delta_revenue>0?'success':'danger')+' mr-2'">
                                <i :class="'fa fa-arrow-'+(data.delta_revenue>0?'up':'down')"></i> {{data.delta_revenue}} %</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Profit"
                                type="gradient-orange"
                                :sub-title="Intl.NumberFormat('da-DK').format(data.profit)"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0"
                    >
                        <template slot="footer"  v-if="data.delta_profit!=0">
                            <span :class="'text-'+(data.delta_profit>0?'success':'danger')+' mr-2'">
                                <i :class="'fa fa-arrow-'+(data.delta_profit>0?'up':'down')"></i> {{data.delta_profit}} %</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Prod. Sold"
                                type="gradient-green"
                                :sub-title="Intl.NumberFormat('da-DK').format(data.productsSold)"
                                icon="ni ni ni-cart"
                                class="mb-4 mb-xl-0"
                    >
                        <template slot="footer"  v-if="data.delta_amount_of_products!=0">
                            <span :class="'text-'+(data.delta_amount_of_products>0?'success':'danger')+' mr-2'">
                                <i :class="'fa fa-arrow-'+(data.delta_amount_of_products>0?'up':'down')"></i> {{data.delta_amount_of_products}} %</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>

                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="#Customer"
                                type="gradient-info"
                                :sub-title="Intl.NumberFormat('da-DK').format(data.noOfCustomers)"
                                icon="ni ni ni-circle-08"
                                class="mb-4 mb-xl-0"
                    >
                        <template slot="footer"  v-if="data.delta_customer_count!=0">
                            <span :class="'text-'+(data.delta_customer_count>0?'success':'danger')+' mr-2'">
                                <i :class="'fa fa-arrow-'+(data.delta_customer_count>0?'up':'down')"></i> {{data.delta_customer_count}} %</span>
                            <span class="text-nowrap">Since last month</span>
                        </template>
                    </stats-card>
                </div>
            </div>
            <br>
            <!--Charts-->
            <div class="row">
                <div class="col-xl-8 mb-5 mb-xl-0">
                    <div class="row">
                        <div class="col">
                            <card header-classes="bg-transparent">
                                <div slot="header" class="row align-items-center">
                                    <div class="col d-xs-block">
                                        <h6 class="text-uppercase text-muted ls-1 mb-1"></h6>
                                        <h5 class="h3 mb-0">Total orders</h5>
                                    </div>
                                    <div class="col ">
                                        <ul class="nav nav-pills justify-content-end">
                                            <li class="nav-item">
                                                <a class="nav-link py-2 px-3"
                                                   :class="{active: (showWeeklyTimeline ? 1: 0)}"
                                                   @click="setTimeline('weekly')">
                                                    <span class="d-none d-md-block">Week</span>
                                                    <span class="d-md-none">W</span>
                                                </a>
                                            </li>
                                            <li class="nav-item mr-2 mr-md-0">
                                                <a class="nav-link py-2 px-3"
                                                   :class="{active: (showMonthlyTimeline ? 1: 0)}"
                                                   @click="setTimeline('monthly')">
                                                    <span class="d-none d-md-block">Month</span>
                                                    <span class="d-md-none">M</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div id="week-timeline" v-if="showWeeklyTimeline">
                                    <lineChart
                                            :height="timelineSize.height"
                                            :data="data.timelineWeekly"
                                            :metric="'amount_of_products'"
                                            :noGraph="4"
                                            :show="showWeeklyTimeline"/>
                                </div>
                                <div id="month-timeline" v-if="showMonthlyTimeline">
                                    <lineChart
                                            :height="timelineSize.height"
                                            :data="data.timelineMonthly"
                                            :metric="'amount_of_products'"
                                            :noGraph="2"
                                            :show="showMonthlyTimeline"/>
                                </div>
                            </card>
                        </div>
                    </div>
                    <br>
                    <!--Heatmap - weekday & hour-->
                     <div class="row">
                         <div class="col">
                             <card header-classes="bg-transparent">
                                  <div slot="header" class="row align-items-center">
                                      <div class="col">
                                          <h6 class="text-uppercase text-muted ls-1 mb-1"></h6>
                                          <h5 class="h3 mb-0">Avg. #customers</h5>
                                      </div>
                                  </div>
                                <heatmap :data="data.heatmap"
                                  :yAxisDimension="'weekday_name'"
                                  :xAxisDimension="'hour'"
                                  :metric="'customer_count'"
                                  :id="'executive-summary'"/>
                             </card>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <!--Productgroup-->
                    <div class="row">
                        <div class="col">
                            <card header-classes="bg-transparent">
                                <div slot="header" class="row align-items-center">
                                    <div class="col">
                                        <h6 class="text-uppercase text-muted ls-1 mb-1"></h6>
                                        <h5 class="h3 mb-0">Revenue pr. product group</h5>
                                    </div>

                                </div>
                                    <barChart :direction="'vertical'"
                                      :dimension="'product_group'"
                                      :metric="'amount_of_products'"
                                      :data="data.productGroups"
                                      :chartSize="productGroupsChartSize"
                                      :chartMargin="productGroupsChartMargin"
                                      :noGraph="1"/>

                            </card>
                        </div>
                    </div>

                    <br>
                    <!--Products-->
                     <div class="row">
                         <div class="col">
                             <card header-classes="bg-transparent">
                                  <div slot="header" class="row align-items-center">
                                      <div class="col">
                                          <h6 class="text-uppercase text-muted ls-1 mb-1"></h6>
                                          <h5 class="h3 mb-0">Products sold</h5>
                                      </div>
                                  </div>
                                    <barChart :direction="'horizontal'" :data="data.products"
                                      :dimension="'product'"
                                      :chartSize="productsChartSize"
                                      :chartMargin="productsChartMargin"
                                      :metric="'amount_of_products'"
                                      :noGraph="3"/>
                             </card>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End charts-->
        </div>
    </div>
</template>
<script>
  // Charts
  import LineChart from '@/components/Charts/LineChart.vue';
  import BarChart from '@/components/Charts/BarChart.vue';
  import Heatmap from '@/components/Charts/Heatmap.vue';

  export default {
    components: {
      lineChart: LineChart,
      barChart: BarChart,
      heatmap: Heatmap,
    },
    props:['data'],
    data() {
      return {
          showWeeklyTimeline: false,
          showMonthlyTimeline: true,
          showDailyTimeline: false,
          timelineSize: {height: 350},
          productGroupsChartSize: {width: 400, height: 350},
          productGroupsChartMargin: {top: 10, right: 15, bottom: 40, left: 15},
          productsChartSize: {width: 400, height: 350},
          productsChartMargin: {top: 20, right: 30, bottom: 40, left: 210},
      };
    },

    methods: {
      setTimeline(viewType){
        if(viewType=='daily'){
          // eslint-disable-next-line
          this.showDailyTimeline = true;
          this.showWeeklyTimeline = false;
          this.showMonthlyTimeline = false;
        }
        if(viewType=='weekly'){
          // eslint-disable-next-line
          this.showDailyTimeline = false;
          this.showWeeklyTimeline = true;
          this.showMonthlyTimeline = false;
        }
        if(viewType=='monthly'){
          // eslint-disable-next-line
          this.showDailyTimeline = false;
          this.showWeeklyTimeline = false;
          this.showMonthlyTimeline = true;
        }

      },
    },
    mounted() {

    }
  };
</script>
<style></style>
