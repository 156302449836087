import Vue from 'vue'
import VueRouter from 'vue-router'

import Activity from '@/pages/Activity.vue'
import Analytics from '@/pages/Analytics.vue'
import Businesses from '@/pages/Businesses.vue'
import Credentials from '@/pages/Credentials.vue'
import Changelog from '@/pages/Changelog.vue'
import ForgotPassword from '@/pages/ForgotPassword.vue'
import Login from '@/pages/Login.vue'
import NewUser from '@/pages/NewUser.vue'
import Profile from '@/pages/Profile.vue'
import Users from '@/pages/Users.vue'
import Watchtower from '@/pages/Watchtower.vue'
import Welcome from '@/pages/Welcome.vue'
import Workflows from '@/pages/Workflows.vue'
import Workflow from '@/pages/Workflow.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,},
  {    path: '/activity',
    name: 'Activity',
    component: Activity,},
  {    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    children: [{
            path: ':viewType',
            name: 'Analytics View',
            component: Analytics,
            }
    ]},
  {    path: '/businesses',
    name: 'Businesses',
    component: Businesses,},
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog,},
  {
    path: '/forgot',
    name: 'Forgot Password',
    component: ForgotPassword,},
  {
    path: '/credentials',
    name: 'Credentials',
    component: Credentials,
    children: [{
        path: ':platform/auth',
        name: 'Credentials Auth',
        component: Credentials,

        }
    ]},
  {
    path: '/login',
    name: 'Login',
    component: Login,},
  {
    path: '/new',
    name: 'New User',
    component: NewUser,},
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,},

  { path: '/watchtower',
    name: 'Watchtower',
    component: Watchtower,
    children: [{
            path: ':viewType',
            name: 'Watchtower View',
            component: Watchtower,
            }],
    },
  {
    path: '/workflows',
    name: 'Workflows',
    component: Workflows,
    children: [{
            path: ':workflow',
            name: 'Workflow',
            component: Workflow,
            }
    ]},
  {    path: '/users',
    name: 'Users',
    component: Users,},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
import axios from 'axios';
import config from '@/config';

router.beforeEach((to, from, next) => {
  if (!localStorage.Authorization){
    if(to.name!=="Login" && to.name!=="Forgot Password" && to.name!=="New User" ){
     next({ name: 'Login' })
    }else(
      next()
    )
  }
  else {
    const path = `${config.backend}/status`;
    axios.get(path, {headers:{ Authorization:`${localStorage.Authorization}`}})
    .then((result) => {
      localStorage.setItem('userName', result.data.name);
      localStorage.setItem('authenticated', true);
      next()
    })
    .catch((error) => {
      // eslint-disable-next-line
      console.log(error)
      localStorage.setItem('authenticated', false);
      if (error.response.status === 401) {
        localStorage.removeItem('Authorization');
        next({ name: 'Login' })
      }
    });
  }

})
export default router;
