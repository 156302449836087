<template>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10 col-md-10">
        <br>
        <br>
        <div class="card bg-secondary shadow border-0">
          <div class="card-body" v-if="!tokenValidated">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Link has expired. Contact <a href="mailto:support@chron.cloud">Support</a></span>
            </div>
          </div>
          <div class="card-body" v-if="tokenValidated">
            <div class="col">
              <div class="row  no-gutters">
                <h1>
                Getting started | {{user.name}}
                </h1>
              </div>
              <div class="row  no-gutters">
                <div class="col">
                  <base-progress type="default" :value="Math.round(percentage*10)" :label="pages[progress]"></base-progress>
                </div>
              </div>
              <br>
              <div v-if="progress==0">
                <div class="row">
                  <div class="col">
                    Welcome to <b>CHRONOS</b>, {{user.name}}. This guide will help you setup your account and configure your notifications.
                  </div>
                </div>
              </div>
              <div v-if="progress==1">
                <div class="row">
                  <div class="col-md-6">
                    <h4>Email</h4>
                  </div>
                  <div class="col-md-6">
                    <base-input
                          :placeholder="user.email" disabled></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <h4>Name</h4>
                  </div>
                  <div class="col-md-6">
                    <base-input placeholder="Name"
                                v-model="user.name"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <h4>Phone number</h4>
                  </div>
                  <div class="col-md-6">
                    <base-input placeholder="Phonenumber"
                                v-model="user.phone_number"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                      <h4>Password</h4>
                  </div>
                  <div class="col-md-6">
                    <base-input alternative placeholder="Password"
                        type="password"
                        v-model="user.password"
                        @change="checkPassword"
                        :valid="allowSend"></base-input>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                      <h4>Repeat password</h4>
                  </div>
                  <div class="col-md-6">
                    <base-input alternative placeholder="Repeat password"
                        v-model="repeatPassword"
                        type="password"
                        @change="checkPassword"
                        :valid="allowSend"></base-input>
                        <div class="row mt-3">
                  <div class="col-12 text-left">
                      <small>Password must contain a number, symbol and both size letters </small>
                  </div>
              </div>
                  </div>
                </div>
              </div>
              <div v-if="progress==2">
                <div class="row  no-gutters">
                  <div class="col">
                    <h1>Choose which workflows you find relevant and when to receive noticications.</h1>
                    <div class="table-responsive">
                      <base-table thead-classes="thead-light"
                        :data="Object.keys(workflowNotifications)">
                        <template slot="columns"  class="d-none d-lg-table-cell">
                          <th class="d-lg-table-cell">Workflow</th>
                          <th class="d-none d-lg-table-cell">Notify when</th>
                          <th class="d-none d-lg-table-cell">Mail</th>
                          <th class="d-none d-lg-table-cell">SMS</th>
                        </template>
                        <template slot-scope="{row}">
                          <td scope="row" >
                              {{row}}
                          </td>
                          <td scope="row"  class="d-none d-lg-table-cell">
                            <select v-model="workflowNotifications[row]['when']">
                              <option v-for="sched in notifyWhen"
                                  :key="sched"
                                  :value="sched" >
                                {{sched}}
                              </option>
                            </select>
                          </td>
                          <td scope="row" class="d-none d-lg-table-cell">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                                    v-model="workflowNotifications[row]['email']"
                                                    :id="'customCheckMail-'+row">
                              <label class="custom-control-label" :for="'customCheckMail-'+row"></label>
                            </div>
                          </td>
                          <td scope="row" class="d-none d-lg-table-cell">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                              v-model="workflowNotifications[row]['sms']"
                                            :id="'customCheckSMS-'+row">
                              <label class="custom-control-label" :for="'customCheckSMS-'+row"></label>
                            </div>
                          </td>
                        </template>
                      </base-table>
                    </div>
                  </div>
                </div>
              </div>
               <div v-if="progress==maxPages">
                <div class="row">
                  <div class="col">
                    <h2>Setup Finished</h2>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col">
                <base-button outline
                      v-if="progress>0"
                      type="default"
                      @click="previousPage()">
                                 <i class="ni ni-bold-left"/>
                </base-button>
              </div>
              <div class="col">
                <base-button outline type="default" @click="nextPage()" class="float-right" >
                             {{(progress==0)?'Get started' : ''}}
                             <a href="/login" v-if="progress==maxPages">Continue to platform!</a>
                             <i class="ni ni-bold-right"/>

                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import User from '@/data-models/user.json';
  import config from '@/config';
  import axios from 'axios';

  export default {
    components: {
    },
    data() {
      return {
        view: 'NOTIFICATION SETTINGS',
        msg: 'Loading',
        tokenValidated: false,
        progress: 0,
        percentage: 0,
        maxPages:3,
        user: User,
        repeatPassword: "",
        allowSend: false,
        workflowNotifications: {},
        notifyWhen: ['NEVER','FAILURE','SUCCESS','ALWAYS'],
        pages: ['INTRODUCTION', 'USER INFORMATION', 'NOTIFICATION'],

      };
    },
    methods: {
      validateToken(){
        if(this.$router.history.current.query.token){
          const token = this.$router.history.current.query.token;
          const path = `${config.backend}/users?token=${token}`;
          axios.get(path, {headers:{ Authorization:`${token}`}})
            .then((result) => {
              this.user = result.data
              this.tokenValidated=true;
              localStorage.setItem('Authorization', token);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
      checkPassword(){
        // eslint-disable-next-line
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        // eslint-disable-next-line
        var formatNumbers = /123456789/;
        if(!format.test(this.user.password)){
          this.passwordMessage = "Password must contain a special character"
          this.allowSend = false;
        } else {
          if(!/\d/.test(this.user.password)){
            this.passwordMessage = "Password must contain a number"
            this.allowSend = false;
          }else{
            if(!/[A-Z]/.test(this.user.password)){
            this.passwordMessage = "Password must contain an uppercase character"
            this.allowSend = false;
            }else{
              if(this.user.password!=this.repeatPassword){
                this.passwordMessage = "Password does not match"
                this.allowSend = false;
              } else{
                this.passwordMessage = "Everything looks fine!";
                this.allowSend = true;
              }
            }

          }
        }
      },
      saveUser(user){
        var path = `${config.backend}${config.users}/${user._id}`
        // eslint-disable-next-line
        axios.put(path, user, {headers:{ Authorization:`${localStorage.Authorization }`}})
          .then((result) => {
            console.log(result);
            this.getWorkflowNotifications(user);
            this.increment();
        })
      },
      getWorkflowNotifications(user){
        var path = `${config.backend}${config.workflow_notifications}/${user._id}/workflows`
        axios.get(path, {headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.workflowNotifications = result.data;

          })
          .catch((error) => {
            console.log(error);
            // eslint-disable-next-line

          });
      },
      saveWorkflowNotifications(user,workflowNotifications){
        var path = `${config.backend}${config.workflow_notifications}/${user._id}/workflows`
        console.log(workflowNotifications);
        axios.put(path, workflowNotifications,{headers:{Authorization:`${localStorage.Authorization}`}})
          .then((result) => {
            this.increment();
            this.workflowNotifications = result.data;

          })
          .catch((error) => {
            console.log(error);
            // eslint-disable-next-line

          });
      },
      previousPage(){
        if(this.progress>=1){
          this.percentage -= 1 /this.maxPages*10;
          this.progress -=1
        }
      },
      increment(){
        if(this.progress<3){
          this.percentage += 1 /this.maxPages*10;
          this.progress +=1
        }
      },
      nextPage(){
        if(this.progress==0){
          this.increment();
        }
        if(this.progress==1 && this.allowSend){
          this.saveUser(this.user);
        }
        if(this.progress==2){
          this.saveWorkflowNotifications(this.user,this.workflowNotifications);
        }
        if(this.progress==3){
          console.log('salmonking')
        }
      },
    },
    mounted() {
     this.validateToken();
    }
  };
</script>
<style></style>
