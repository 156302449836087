<template>
    <div>
      <base-header type="gradient-dark" class="pb-6 pb-8 pt-5 pt-md-7">
        <!-- Small filter -->
        <div class="container d-lg-none">
          <div class="row">
            <div class="col">
              <b-button-group
                                  aria-label="Basic example"
                                  data-toggle="buttons">
              <button type="button"  @click="setViewType(button)"
                                       v-for="button in views"
                                       :key="button.value"
                                       :class="button.class"
                                       :aria-pressed="button.state">{{button.text}}</button>
            </b-button-group>
            </div>
            <div class="col-12">
                <base-input type="date" v-model="startDate" @click="getData(view.value, startDate, endDate, productID)"/>

              <base-input type="date"  v-model="endDate" @click="getData(view.value, startDate, endDate, productID)"/>
            </div>
          </div>
        </div>
        <!-- Big filter -->
        <div class="row">
          <div class="col d-none d-lg-block">
            <div class="row">
              <div class="col-2">
                  <base-input type="date"  v-model="startDate" @change="getData(view.value, startDate, endDate, productID)"/>
              </div>
              <div class="col-2">
                <base-input type="date"  v-model="endDate" @change="getData(view.value, startDate, endDate, productID)"/>
              </div>


              <div class="offset-md-2">
                <b-button-group
                                    aria-label="Basic example"
                                    data-toggle="buttons">
                  <button type="button"  @click="setViewType(button)"
                                           v-for="button in views"
                                           :key="button.value"
                                           :class="button.class"
                                           :aria-pressed="button.state">{{button.text}}</button>
                </b-button-group>
              </div>
            </div>
            {{dashboardData[view.value]}}
          </div>
        </div>
      </base-header>
    </div>
</template>
<script>
  import axios from 'axios';
  import config from '@/config';

  export default {
    components: {
    },
    data() {
      return {
        dashboardData: {},
        startDate: '',
        endDate: '',
         view: {value:'users',
            text:'Users',
            class:'btn btn-secondary'},
         views: [{value:'users',
              text:'Users',
              class:"btn btn-outline-secondary",
              state: true},
            {value:'endpoints',
            text:'Endpoints',
            class:"btn btn-outline-secondary",
            state: false},
            {value:'workflows',
            text:'Workflows',
            class:"btn btn-outline-secondary", state: false},
            {value:'datasources',
            text:'Datasources ',
            class:"btn btn-outline-secondary", state: false},
            ],

      };
    },
    methods: {
      setViewType(view){
        this.view = view;
        this.getData(view.value);
        for( var i=0; i<this.views.length;i++){
          this.views[i].class = 'btn btn-outline-secondary';
          this.views[i].state = false;
          this.views[i].class = 'btn btn-outline-secondary';
        }
        this.view.class = 'btn btn-secondary';
        if(this.$route.fullPath.includes('watchtower/')){
          this.$router.push(this.view.value);
        } else {
          this.$router.push('watchtower/'+this.view.value);
        }
      },
    getData(view, startDate, endDate){
      var path = `${config.backend}/logs/${view}?`
      if(startDate){
        path += "startDate="+startDate;
      }
      if(endDate){
        path += "&endDate="+endDate;
      }
      axios.get(path, {headers:{params: this.axiosParams,
                                Authorization:`${localStorage.Authorization}`}})
        .then((result) => {

          this.dashboardData[this.view.value] = result.data[this.view.value];
          this.startDate = result.data.startDate;
          this.endDate = result.data.endDate;
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401) {
            localStorage.removeItem('Authorization');
            window.location = '/';
          }
        });
    },
    handleRouting(){
      var viewType = this.$router.history.current.params.viewType;
      for( var i=0; i<this.views.length;i++){
        if(this.views[i].value==viewType && viewType!=this.view.value){
          this.setViewType(this.views[i]);
        }
      }
      // eslint-disable-next-line
      var startDate = this.$route.query.startDate;
      (startDate) ? this.startDate = startDate:'';
      // eslint-disable-next-line
      var endDate = this.$route.query.endDate;
      (endDate) ? this.endDate = endDate:'';
      },
    },
    mounted() {
      this.handleRouting();
      this.getData(this.view.value);
    }
  };
</script>
<style></style>
